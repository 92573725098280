import React, { useEffect, useState } from 'react'

import { QueryStatus } from 'react-query'
import { useGetFeatureTogglesQuery } from '../../services/service-feature-toggles/useGetFeatureTogglesQuery'
import { FeatureFlags } from '../../types/launchdarkly/featureFlags'

export interface FeatureTogglesFlagsContext {
    flags?: FeatureFlags
    status: QueryStatus
}

interface FeatureTogglesProviderProps {
    children?: React.ReactNode
}

export const FeatureTogglesContext = React.createContext<FeatureTogglesFlagsContext | undefined>({
    status: 'loading',
})

export const FeatureTogglesProvider = ({ children }: FeatureTogglesProviderProps): JSX.Element => {
    const [contextData, setContextData] = useState<FeatureTogglesFlagsContext>({
        status: 'loading',
    })
    const { data, status } = useGetFeatureTogglesQuery()

    useEffect(() => {
        if (data?.flags) {
            setContextData({ flags: data.flags, status })
        }
    }, [status, data])

    const value = React.useMemo(() => contextData, [contextData])

    return <FeatureTogglesContext.Provider value={value}>{children}</FeatureTogglesContext.Provider>
}

export const withFeatureTogglesConsumer = <P extends object>(
    Component: React.ComponentType<P>,
): ((props: P) => JSX.Element) => {
    return function ContextualComponent(props: P) {
        return (
            <FeatureTogglesContext.Consumer>
                {ldClient => <Component {...props} ldClient={ldClient} />}
            </FeatureTogglesContext.Consumer>
        )
    }
}
