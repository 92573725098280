import Axios from '../axios/axios.instance'
import { isMobile } from '../toolbox/devices'

export const adminLogout = async (shouldCallBackend = true, session = false): Promise<void> => {
    if (shouldCallBackend) {
        await Axios.post(`${process.env.REACT_APP_MEDGO_URL}/admin/logout`).catch(err => {
            console.error(`Error while calling serviceapp adminLogout: ${err}`)
        })
    }
    if (session) {
        await Axios.post(`${process.env.REACT_APP_BFF_ADMIN_URL}/logout`).catch(err => {
            console.error(`Error while calling bff-admin Logout: ${err}`)
        })
    }

    localStorage.clear()

    if (isMobile()) {
        window.location.href = `${process.env.REACT_APP_MEDGO_URL}/mobile#/adminLogin`
    } else {
        window.location.href = `${process.env.REACT_APP_MEDGO_URL}/loginadmin`
    }
}
