import dePageDescription from '../../lang/de/pageDescription.json'
import frPageDescription from '../../lang/fr/pageDescription.json'
import { AvailableLangs, AvailableLangsMomentCorrespondance } from '../reducers/lang.reducer'
import { IReduxAction } from '../store.types'
import LangTypes from '../types/lang.types'

function setTitleDescription(lang: string): void {
    if (lang === AvailableLangs.fr) {
        document.title = frPageDescription.title
        document
            .getElementsByName('description')
            .forEach((e): void => e.setAttribute('content', dePageDescription.description))
    }
}

export function setLangAction(lang = 'fr'): (dispatch: (action: IReduxAction) => void) => void {
    return (dispatch: (action: IReduxAction) => void): void => {
        const language = AvailableLangsMomentCorrespondance[lang]
            ? AvailableLangsMomentCorrespondance[lang]
            : AvailableLangsMomentCorrespondance['fr']
        require('moment/locale/'.concat(language))
        setTitleDescription(lang)
        localStorage.setItem('lang', lang)
        dispatch({
            type: LangTypes.SET_LANG,
            payload: lang,
        })
    }
}
