import TemplateTypes from '../types/template.types'
import { IReduxAction } from '../store.types'

const initialState = {
    byMissionId: {},
}

export default function TemplateReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case TemplateTypes.FETCH_TEMPLATES:
            return { ...state, byMissionId: { ...action.payload.byMissionId } }
        default:
            return state
    }
}
