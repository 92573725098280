import { IReduxAction } from '../store.types'
import MissionPeriodsActionTypes from '../types/missionPeriods.types'

const initialState: any = {
    isLoadingMissionsPeriods: false,
    missionPeriods: [],
}
export default function missionsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case MissionPeriodsActionTypes.SET_IS_LOADING_MISSIONS_PERIODS:
            return {
                ...state,
                isLoadingMissionsPeriods: action.payload,
            }
        case MissionPeriodsActionTypes.FETCH_MISSIONS_PERIODS:
            return {
                ...state,
                missionPeriods: action.payload,
            }
        default:
            return state
    }
}
