import { IReduxAction } from '../store.types'
import AppTypes from '../types/app.types'
const initialState: any = {
    isLoadingApp: true,
    isLoadingContractPage: true,
}
export default function appReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AppTypes.SET_IS_APP_READY:
            return {
                ...state,
                isLoadingApp: action.payload,
            }
        case AppTypes.SET_IS_CONTRACT_PAGE_READY:
            return {
                ...state,
                isLoadingContractPage: action.payload,
            }
        default:
            return state
    }
}
