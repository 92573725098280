import Axios from '../axios/axios.instance'
import { AxiosResponse } from 'axios'
import { decode, JwtPayload } from 'jsonwebtoken'
import { Token, DeprecatedToken } from '../../../toolbox/jwt/jwt-tools'

export const refreshToken = async (payload: any): Promise<AxiosResponse<{ token: string }>> => {
    try {
        return Axios.get(`${process.env.REACT_APP_SERVICE_AUTH_URL}token/refresh`)
    } catch (error) {
        if (payload.hasOwnProperty('userType')) {
            console.error(
                `An error has occurred in refreshToken for ${payload.userType} with payload: ${JSON.stringify(
                    payload,
                )}: ${error}`,
            )
        } else {
            console.error(`An error occurred with payload : ${JSON.stringify(payload)}: ${error}`)
        }
        throw new Error('errorOccur')
    }
}

export const decodeToken = (token: string): (JwtPayload & Token) | null => {
    const decoded = decode(token) as JwtPayload
    if (!decoded) {
        return null
    }
    if ('userType' in decoded) {
        return decoded as Token
    }
    const { userId, adminId, idLanguage, tokenValidationNumber } = decoded as DeprecatedToken
    const userType = userId ? 'worker' : 'admin'
    return {
        ...decoded,
        user: {
            id: (userId ?? adminId) as number,
            idLanguage,
            tokenValidationNumber,
            userType,
        },
        userType,
    }
}
