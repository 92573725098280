import { IInterimAgencyHasInterimAgencyRequest } from '../../../Models/InterimAgencyHasInterimAgencyRequest'
import { IReduxAction } from '../store.types'
import InterimAgencyHasInterimAgencyRequestTypes from '../types/interimAgencyHasInterimAgencyRequest.types'

const initialState: any = {
    interimAgencyHasInterimAgencyRequestDict: {},
    isLoadingFetchInterimAgencyHasInterimAgencyRequest: false,
}
export default function interimAgencyHasInterimAgencyRequestReducer(
    state: any = initialState,
    action: IReduxAction,
): void {
    switch (action.type) {
        case InterimAgencyHasInterimAgencyRequestTypes.FETCH_INTERIM_AGENCY_HAS_INTERIM_AGENCY_REQUEST:
            const dict: { [x: string]: IInterimAgencyHasInterimAgencyRequest } = {}
            action.payload.forEach((element: IInterimAgencyHasInterimAgencyRequest) => {
                dict[`${element.mission}-${element.worker}`] = element
            })
            return {
                ...state,
                interimAgencyHasInterimAgencyRequestDict: { ...dict },
            }
        case InterimAgencyHasInterimAgencyRequestTypes.SET_IS_LOADING_FETCH_INTERIM_AGENCY_HAS_INTERIM_AGENCY_REQUEST:
            return {
                ...state,
                isLoadingFetchInterimAgencyHasInterimAgencyRequest: action.payload,
            }
        default:
            return state
    }
}
