import { IReduxAction } from '../store.types'
import InstitutionGroupTypes from '../types/institutionGroup.types'
import { fetchInstitutionGroups } from '../../services/InstitutionGroup/institutionGroup.service'
import { IQueryParams } from 'npm-medgo-query'
import { IInstitutionGroup } from '../../../Models/InstitutionGroup'

export function setIsLoadingFetchInstitutionGroupsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTION_GROUPS,
            payload: bool,
        })
    }
}

export function fetchInstitutionGroupsAction(
    param: IQueryParams = {},
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const institutionGroups = await fetchInstitutionGroups(param)
        dispatch({
            type: InstitutionGroupTypes.FETCH_INSTITUTION_GROUPS,
            payload: institutionGroups,
        })
        const institutionGroupDict = institutionGroups.reduce(
            (acc: Record<number, IInstitutionGroup>, current: IInstitutionGroup) => {
                acc[current.id] = current
                return acc
            },
            {},
        )
        dispatch({
            type: InstitutionGroupTypes.FETCH_INSTITUTION_GROUP,
            payload: Object.values(institutionGroupDict).reduce((acc: Record<number, IInstitutionGroup[]>, current): {
                [key: number]: IInstitutionGroup[]
            } => {
                const idInstitutions = current.institution
                const institutionGroup = JSON.parse(JSON.stringify(current))
                delete institutionGroup.institution
                idInstitutions.forEach((idInstitution: number): void => {
                    if (acc[idInstitution]) {
                        acc[idInstitution].push(institutionGroup)
                    } else {
                        acc[idInstitution] = [institutionGroup]
                    }
                })
                return acc
            }, {}),
        })
    }
}
