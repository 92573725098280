import { IQueryParams } from 'npm-medgo-query'
import AxiosInstance from '../../src/modules/axios/axios.instance'
import { PossibleApis, PossibleFetchs } from '../constants/Api.constants'
import generateParams from '../url/customParamGenerator'

export function fetchFactory<T>(
    modalName: PossibleFetchs,
    api = PossibleApis.serviceApi,
    apiVersion: number = 1,
): (defaultParams: IQueryParams, queryParams?: IQueryParams, queryUrlParams?: any) => Promise<T[]> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams, queryUrlParams?: any): Promise<T[]> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {})
        const queryUrlKeys = queryUrlParams ? Object.keys(queryUrlParams) : []
        const res = await AxiosInstance.get(
            api +
                `/api/v${apiVersion}/`.concat(
                    modalName,
                    '?',
                    generateParams(params),
                    queryUrlKeys.length > 0
                        ? '&'.concat(queryUrlKeys.map((e): string => e + '=' + queryUrlParams[e]).join('&'))
                        : '',
                ),
        )
        return res.data
    }
}

export function fetchNormalizedFactory<T>(
    modalName: PossibleFetchs,
    api = PossibleApis.serviceApi,
): (defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T> {
    return async (defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {})
        const res = await AxiosInstance.get(api + '/api/v1/'.concat(modalName, '?', generateParams(params)))
        return res.data.reduce((p: any, c: any): any => {
            p[c.id ? c.id : -1] = c
            return p
        }, {})
    }
}
