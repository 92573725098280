import { Permission, UserPermissions } from '../../types/permission'

const INSTITUTION_RESOURCE_SCOPE_PATTERN = 'Hublo::Match::Admin::Institution:__INSTITUTION_ID__'

const institutionScope = (institutionId: number): string => {
    return INSTITUTION_RESOURCE_SCOPE_PATTERN.replace('__INSTITUTION_ID__', institutionId.toString())
}

const hasPermissionWithinInstitution = (
    permission: Permission,
    permissions: UserPermissions,
    institutionId: number,
): boolean => {
    return permissions[institutionScope(institutionId)]?.includes(permission)
}

export const isAllowedTo = ({
    institutionIds,
    permission,
    permissions,
}: {
    institutionIds?: number[]
    permission: Permission
    permissions?: UserPermissions
}): boolean => {
    if (!permissions || !institutionIds) {
        return false
    }

    return institutionIds.some(id => hasPermissionWithinInstitution(permission, permissions, id))
}
