import { Url } from 'npm-medgo-toolbox'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { INormalizedSignatures, ISignature } from '../../../Models/Signature'
import { fetchNormalizedSignatureAction, fetchSignatureByIdAction } from '../../store/actions/signatures.actions'
import { fetchContractByIdAction } from '../../store/actions/contracts.actions'
import { IContract } from '../../../Models/Contract'
interface IReduxPropsSignatureCantSign {
    normalizedSignatures: INormalizedSignatures
    lang: any
    contract: IContract
    signature: ISignature
}
interface IReduxActionsSignatureCantSign {
    fetchNormalizedSignatureAction: (idContracts: number[], isWorker?: boolean) => Promise<void>
    fetchContractByIdAction: (idContract: number, isWorker?: boolean) => Promise<void>
    fetchSignatureByIdAction: (id: number, isWorker?: boolean) => Promise<void>
}

class SignatureCantSign extends Component<
    IReduxActionsSignatureCantSign & IReduxPropsSignatureCantSign & RouteComponentProps<{ idContract: string }>,
    {
        isLoading: boolean
    }
> {
    constructor(
        props: IReduxActionsSignatureCantSign &
            IReduxPropsSignatureCantSign &
            RouteComponentProps<{ idContract: string }>,
    ) {
        super(props)
        this.state = { isLoading: true }
    }
    async componentDidMount(): Promise<void> {
        Url.checkAndSaveTokensFromUrl()
        this.setState({
            isLoading: true,
        })
        await this.props.fetchContractByIdAction(parseInt(this.props.match.params.idContract), true)
        await this.props.fetchNormalizedSignatureAction([parseInt(this.props.match.params.idContract)], true)
        const signature = Object.keys(this.props.normalizedSignatures)
            .map((e: string): ISignature => this.props.normalizedSignatures[parseInt(e)])
            .find((e: ISignature): boolean => e.type === 'worker')
        await this.props.fetchSignatureByIdAction(signature && signature.id ? signature.id : -1, true)
        this.setState({
            isLoading: false,
        })
    }

    render(): JSX.Element {
        const { isLoading } = this.state
        return (
            <div className="container">
                <div className="hero is-medium">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4 offset-4">
                                    <img className="img-fluid" src="/images/contractLogo.png" />
                                </div>
                            </div>
                        </div>
                        {isLoading === false ? (
                            this.props.contract.isCancelled ? (
                                <>
                                    <div className="col-12 text-center">
                                        <p className="has-margin-top is-medium text-primary">
                                            <strong>{this.props.lang.YourMissionHaveBeenCancelled}</strong>
                                        </p>
                                    </div>
                                    <div className="col-12 text-center">
                                        <p className="has-margin-top is-large">
                                            <strong>{this.props.lang.inCaseOfProblem}</strong>
                                        </p>
                                    </div>
                                </>
                            ) : this.props.signature.isSigned === true ? (
                                <>
                                    <div className="col-12 text-center">
                                        <h2 className="has-margin-top is-medium text-primary">
                                            {this.props.lang.youHaveAlreadySigned}
                                        </h2>
                                    </div>
                                    <div className="col-12 text-center">
                                        <p className="has-margin-top is-large">
                                            <strong>{this.props.lang.onceSignedContractWillBeAvailable}</strong>
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-12 text-center">
                                        <h2 className="has-margin-top is-medium text-primary">
                                            {this.props.lang.contractIsBeingSignedByInstitution}
                                        </h2>
                                    </div>
                                    <div className="col-12 text-center">
                                        <p className="has-margin-top is-large">
                                            <strong>{this.props.lang.yourInstitutionIsSigningContracts}</strong>.{' '}
                                            {this.props.lang.toSignTryAgainLater}
                                        </p>
                                    </div>
                                </>
                            )
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (centralState: any): IReduxPropsSignatureCantSign => ({
        normalizedSignatures: centralState.signatures.normalizedSignatures,
        signature: centralState.signatures.signature,
        contract: centralState.contracts.contract,
        lang: centralState.language.lang,
    }),
    { fetchNormalizedSignatureAction, fetchSignatureByIdAction, fetchContractByIdAction },
)(SignatureCantSign)
