// German
import deCommon from '../../lang/de/common.json'
import deContract from '../../lang/de/contract.json'
import deDPAE from '../../lang/de/DPAE.json'
import deDPAEMessages from '../../lang/de/DPAEMessages.json'
import deFooter from '../../lang/de/footer.json'
import deInstitution from '../../lang/de/institution.json'
import deMission from '../../lang/de/mission.json'
import deMissionMessages from '../../lang/de/missionMessages.json'
import deNavbar from '../../lang/de/navbar.json'
import deWorker from '../../lang/de/worker.json'
// English
import enCommon from '../../lang/en/common.json'
import enContract from '../../lang/en/contract.json'
import enDPAE from '../../lang/en/DPAE.json'
import enDPAEMessages from '../../lang/en/DPAEMessages.json'
import enFooter from '../../lang/en/footer.json'
import enInstitution from '../../lang/en/institution.json'
import enMission from '../../lang/en/mission.json'
import enMissionMessages from '../../lang/en/missionMessages.json'
import enNavbar from '../../lang/en/navbar.json'
import enWorker from '../../lang/en/worker.json'
// Spanish
import esCommon from '../../lang/es/common.json'
import esContract from '../../lang/es/contract.json'
import esDPAE from '../../lang/es/DPAE.json'
import esDPAEMessages from '../../lang/es/DPAEMessages.json'
import esFooter from '../../lang/es/footer.json'
import esInstitution from '../../lang/es/institution.json'
import esMission from '../../lang/es/mission.json'
import esMissionMessages from '../../lang/es/missionMessages.json'
import esNavbar from '../../lang/es/navbar.json'
import esWorker from '../../lang/es/worker.json'
// French
import frCommon from '../../lang/fr/common.json'
import frContract from '../../lang/fr/contract.json'
import frDPAE from '../../lang/fr/DPAE.json'
import frDPAEMessages from '../../lang/fr/DPAEMessages.json'
import frFooter from '../../lang/fr/footer.json'
import frInstitution from '../../lang/fr/institution.json'
import frMission from '../../lang/fr/mission.json'
import frMissionMessages from '../../lang/fr/missionMessages.json'
import frNavbar from '../../lang/fr/navbar.json'
import frWorker from '../../lang/fr/worker.json'
import { IReduxAction } from '../store.types'
import LangTypes from '../types/lang.types'

export enum AvailableLangs {
    'fr' = 'fr',
    'de' = 'de',
    'en' = 'en',
    'es' = 'es',
}

export const AvailableLangsMomentCorrespondance: Record<string, string> = {
    fr: 'fr',
    de: 'de',
    en: 'en-gb',
    es: 'es',
}

const langs: {
    currentLang: AvailableLangs
    fr: any
    de: any
    en: any
    es: any
} = {
    currentLang: AvailableLangs.fr,
    fr: Object.assign(
        {},
        frCommon,
        frContract,
        frMission,
        frDPAEMessages,
        frWorker,
        frMissionMessages,
        frDPAE,
        frNavbar,
        frInstitution,
        frFooter,
    ),
    de: Object.assign(
        {},
        deCommon,
        deContract,
        deMission,
        deDPAEMessages,
        deWorker,
        deMissionMessages,
        deDPAE,
        deNavbar,
        deInstitution,
        deFooter,
    ),
    en: Object.assign(
        {},
        enCommon,
        enContract,
        enMission,
        enDPAEMessages,
        enWorker,
        enMissionMessages,
        enDPAE,
        enNavbar,
        enInstitution,
        enFooter,
    ),
    es: Object.assign(
        {},
        esCommon,
        esContract,
        esMission,
        esMissionMessages,
        esDPAEMessages,
        esWorker,
        esDPAE,
        esNavbar,
        esInstitution,
        esFooter,
    ),
}

const initialState: any = {
    currentLang: localStorage.getItem('lang') ? localStorage.getItem('lang') : langs.currentLang,
    lang: langs.fr,
}
export default function langReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case LangTypes.SET_LANG:
            return {
                ...state,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lang: langs[action.payload],
                currentLang: action.payload,
            }

        default:
            return state
    }
}
