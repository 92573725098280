import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
const initialState: any = {}

const emptyMiddleware = (): any => (next: any): any => (action: any): any => {
    return next(action)
}
const middlewares = [thunk]
const devTools =
    process.env.NODE_ENV !== 'production'
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
        : applyMiddleware(emptyMiddleware)
const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares), devTools))

export default store
