import { IReduxAction } from '../store.types'
import ContractActionTypes from '../types/contract.types'
import {
    fetchContractById,
    fetchContracts,
    updateContract,
    validateContract,
    fetchNormalizedContracts,
    fuseContracts,
    cancelContract,
    fetchContractsCount,
    fetchSignableContracts,
    bulkSignContracts,
} from '../../services/contract/contracts.service'
import {
    IContract,
    INormalizedContracts,
    IFuseContractsRequest,
    IBulkSignContractsRequest,
} from '../../../Models/Contract'
import { fetchContractDataByIdMission } from '../../services/contract/contractsMission'
import { IQueryParams } from 'npm-medgo-query'
import { PossibleWorkerFilterStates } from '../../constants/Filter.constants'

export function setIsLoadingContractsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_CONTRACTS,
            payload: bool,
        })
    }
}

export function fetchContractsAction(params?: IQueryParams): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contracts = await fetchContracts(params ? params : {})
        dispatch({
            type: ContractActionTypes.FETCH_CONTRACTS,
            payload: contracts,
        })
    }
}

export function setIsLoadingSignableContractsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_SIGNABLE_CONTRACTS,
            payload: bool,
        })
    }
}

export function fetchSignableContractsAction(
    params?: IQueryParams,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contracts = await fetchSignableContracts(params ? params : {})
        dispatch({
            type: ContractActionTypes.FETCH_SIGNABLE_CONTRACT,
            payload: contracts,
        })
    }
}

export function setSignableContractsAction(
    contracts: IContract[],
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        dispatch({
            type: ContractActionTypes.SET_SIGNABLE_CONTRACT,
            payload: contracts,
        })
    }
}

export function setIsLoadingContractsCountAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_CONTRACTS_COUNT,
            payload: bool,
        })
    }
}
export function fetchContractsCountAction(
    params?: IQueryParams,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contractsCount = await fetchContractsCount(params ? params : {})
        dispatch({
            type: ContractActionTypes.FETCH_CONTRACTS_COUNT,
            payload: contractsCount,
        })
    }
}
export function setIsLoadingContractByIdAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_CONTRACT_BY_ID,
            payload: bool,
        })
    }
}

export function fetchContractByIdAction(
    id: number,
    isWorker?: boolean,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contract = await fetchContractById(id, isWorker)
        dispatch({
            type: ContractActionTypes.FETCH_CONTRACT,
            payload: contract,
        })
    }
}

export function setContractAction(contract: IContract): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT,
            payload: Object.assign({}, contract),
        })
    }
}

export function setIsContractResumeModalDisplayedAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_RESUME_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}
export function setIsContractVisualiseModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_VISUALISE_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}
export function setIsContractValidationModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_VALIDATION_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}
export function setIsContractValidationSuccessModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_VALIDATION_SUCCESS_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setIsLoadingContractDataAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_CONTRACT_DATA,
            payload: bool,
        })
    }
}

export function fetchContractDataByIdMissionsAction(
    idMissions: number[],
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contractData = await fetchContractDataByIdMission(idMissions)
        dispatch({
            type: ContractActionTypes.FETCH_CONTRACT_DATA_BY_ID_MISSIONS,
            payload: contractData,
        })
    }
}

export function setContractMissingInfoAction(missingInfo: string[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_MISSING_INFO,
            payload: missingInfo,
        })
    }
}

export function setIsLoadingUpdateContractAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_UPDATE_CONTRACT,
            payload: bool,
        })
    }
}

export function updateContractAction(
    id: number,
    newContract: IContract,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contract = await updateContract(id, newContract)
        dispatch({
            type: ContractActionTypes.UPDATE_CONTRACT,
            payload: contract,
        })
    }
}
export function setIsLoadingValidateContractAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_VALIDATE_CONTRACT,
            payload: bool,
        })
    }
}
export function validateContractAction(id: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contract = await validateContract(id)
        dispatch({
            type: ContractActionTypes.VALIDATE_CONTRACT,
            payload: contract,
        })
    }
}

export function setIsContractFusionModalDisplayedAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_FUSION_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setIsContractsValidationModalDisplayedAction(
    bool: boolean,
): (dispach: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setIsLoadingNormalizedContracts(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_NORMALIZED_CONTRACT,
            payload: bool,
        })
    }
}

export function fetchNormalizedContractsAction(
    params: IQueryParams = {},
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const normalizedContracts = await fetchNormalizedContracts(params)
        dispatch({
            type: ContractActionTypes.FETCH_NORMALIZED_CONTRACTS,
            payload: normalizedContracts,
        })
    }
}

export function setMotifComplementaryStringAction(
    motifComplementaryString: string,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_MOTIF_COMPLEMENTARY_STING,
            payload: motifComplementaryString,
        })
    }
}

export function setSelectMotifErrorMessageAction(errorMessage: string): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_SELECT_MOTIF_ERROR_MESSAGE,
            payload: errorMessage,
        })
    }
}

export function setMotifComplementaryStringErrorMessageAction(
    errorMessage: string,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_MOTIF_COMPLEMENTARY_STRING_ERROR_MESSAGE,
            payload: errorMessage,
        })
    }
}

export function setMotifMatriculeAction(motifMatricule: string): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_MOTIF_MATRICULE,
            payload: motifMatricule,
        })
    }
}

export function setMotifMatriculeErrorMessageAction(
    errorMessage: string,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_MOTIF_MATRICULE_ERROR_MESSAGE,
            payload: errorMessage,
        })
    }
}

export function setNormalizedSelectedContractsAction(
    normalizedContracts: INormalizedContracts,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_NORMALIZED_SELECTED_CONTRACTS,
            payload: normalizedContracts,
        })
    }
}

export function setContractErrorMessageAction(errorMessage: string): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_ERROR_MESSAGE,
            payload: errorMessage,
        })
    }
}

export function setIsLoadingFuseContracts(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_FUSE_CONTRACTS,
            payload: bool,
        })
    }
}

export function fuseContractsAction(
    fuseContractRequest: IFuseContractsRequest,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contract = await fuseContracts(fuseContractRequest)
        dispatch({
            type: ContractActionTypes.FUSE_CONTRACTS,
            payload: contract,
        })
    }
}

export function bulkSignContractsAction(
    bulkSignContractRequest: IBulkSignContractsRequest,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        await bulkSignContracts(bulkSignContractRequest)
        dispatch({
            type: ContractActionTypes.BULK_SIGN_CONTRACTS,
        })
    }
}

export function setIsContractsFuseConfirmationModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACTS_FUSE_CONFIRMATION_MODAL,
            payload: bool,
        })
    }
}

export function setIsSignAllModalDisplayedAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_SIGN_ALL_MODAL,
            payload: bool,
        })
    }
}

export function setIsSignAllConfirmationModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_SIGN_ALL_CONFIRMATION_MODAL,
            payload: bool,
        })
    }
}

export function setIsSignAllSuccessModalDisplayedAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_SIGN_ALL_SUCCESS_MODAL,
            payload: bool,
        })
    }
}

export function setContractSearchInput(str: string): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_SEARCH_INPUT,
            payload: str,
        })
    }
}

export function setIsContractCancelModalDisplayedAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_CANCEL_MODAL,
            payload: bool,
        })
    }
}

export function setIsLoadingCancelContract(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_CANCEL_CONTRACT,
            payload: bool,
        })
    }
}

export function cancelContractAction(id: number): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const contract = await cancelContract(id)
        dispatch({
            type: ContractActionTypes.CANCEL_CONTRACT,
            payload: contract,
        })
    }
}

export function setContractFilterBeginAt(unix: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_BEGIN_AT_FILTER,
            payload: unix,
        })
    }
}
export function setContractFilterEndAt(unix: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_END_AT_FILTER,
            payload: unix,
        })
    }
}

export function setContractFilterStatus(status: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_STATUS_FILTER,
            payload: status,
        })
    }
}

export function setContractFilterCancelledOrFused(status: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_CANCELLED_OR_FUSED_FILTER,
            payload: status,
        })
    }
}

export function setContractFilterInstitutions(institutions: number[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_INSTITUTION_FILTER,
            payload: institutions,
        })
    }
}

export function setContractFilterWorker(worker: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_WORKER_FILTER,
            payload: worker,
        })
    }
}

export function setContractFilterWorkerState(
    state: PossibleWorkerFilterStates,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACT_WORKER_FILTER_STATE,
            payload: state,
        })
    }
}

export function setContractsAction(contracts: IContract[]): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACTS,
            payload: contracts,
        })
    }
}

export function setNormalizedContractsAction(
    normalizedContracts: INormalizedContracts,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_NORMALIZED_CONTRACTS,
            payload: normalizedContracts,
        })
    }
}

export function setIsCantCancelContractModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CANT_CANCEL_CONTRACT_MODAL,
            payload: bool,
        })
    }
}

export function setIsCantValidateContractModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CANT_VALIDATE_CONTRACT_MODAL,
            payload: bool,
        })
    }
}

export function setIsCantFuseContractModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CANT_FUSE_CONTRACT_MODAL,
            payload: bool,
        })
    }
}

export function setContractsPageAction(pageNum: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACTS_PAGE,
            payload: pageNum,
        })
    }
}

export function setNoContractsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_NO_CONTRACT,
            payload: bool,
        })
    }
}

export function setLoadingContractId(id: number): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_LOADING_CONTRACT_ID,
            payload: id,
        })
    }
}

export function setIsLoadingContractDetailsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_CONTRACT_DETAILS,
            payload: bool,
        })
    }
}

export function setIsLoadingContractActionsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_LOADING_CONTRACT_ACTIONS,
            payload: bool,
        })
    }
}

export function setIsContractValidationFailModalDisplayed(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_VALIDATION_FAIL_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setContractsValidationModalInformationAction(
    contractsSelection: any[],
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_CONTRACTS_VALIDATION_MODAL_INFORMATION,
            payload: contractsSelection,
        })
    }
}

export function setIsContractsValidationConfirmationModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_CONFIRMATION_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setIsContractsValidationSuccessModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_SUCCESS_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setAtleastOneContractWasSelectedToBeValidatedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_AT_LEAST_ONE_CONTRACT_WAS_SELECTED_TO_BE_VALIDATED,
            payload: bool,
        })
    }
}
export function setIsContractDownloadAllConfirmationModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_DOWNLOAD_ALL_CONFIRMATION_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setIsContractDownloadAllSuccessModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_DOWNLOAD_ALL_SUCCESS_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setIsContractDownloadAllFailModalDisplayedAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACT_DOWNLOAD_ALL_FAIL_MODAL_DISPLAYED,
            payload: bool,
        })
    }
}

export function setIsContractValidationModalLodaing(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_LOADING,
            payload: bool,
        })
    }
}
