import { IQueryParams } from 'npm-medgo-query'
import { IInstitution, IInstitutionOptions } from '../../../Models/Institution'
import { PossibleApis, PossibleFetchs } from '../../../modules/constants/Api.constants'
import { fetchFactory } from '../../../modules/factory/fetch.factory'
import { fetchByIdSubFieldFactory } from '../../../modules/factory/fetchById.factory'

export async function fetchInstitutions(params: IQueryParams): Promise<IInstitution[]> {
    return fetchFactory<IInstitution>(PossibleFetchs.institutions, PossibleApis.serviceApi)(
        {
            select: ['id', 'name', 'timezone'],
        },
        params,
    )
}

export async function fetchInstitutionOptionByIdInstitution(id: number): Promise<IInstitutionOptions> {
    return fetchByIdSubFieldFactory<IInstitutionOptions>(PossibleFetchs.institutionOptions, PossibleApis.serviceApi)(
        id,
        {
            select: [],
        },
    )
}
