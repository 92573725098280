enum WorkerActionTypes {
    FETCH_WORKERS = 'FETCH_WORKERS',
    FETCH_NORMALIZED_WORKERS = 'FETCH_NORMALIZED_WORKERS',
    FETCH_NORMALIZED_SIGNABLE_WORKERS = 'FETCH_NORMALIZED_SIGNABLE_WORKERS',
    FETCH_WORKER_BY_ID = 'FETCH_WORKER_BY_ID',
    SET_NORMALIZED_SELECTED_WORKERS = 'SET_NORMALIZED_SELECTED_WORKERS',
    SET_IS_LOADING_WORKERS = 'SET_IS_LOADING_WORKERS',
    SET_IS_LOADING_NORMALIZED_WORKERS = 'SET_IS_LOADING_WORKERS',
    SET_IS_LOADING_FETCH_WORKER_BY_ID = 'SET_IS_LOADING_FETCH_WORKER_BY_ID',
    FETCH_NORMALIZED_CONTRACTS_VALIDATION_WORKERS = 'FETCH_NORMALIZED_CONTRACTS_VALIDATION_WORKERS',
}
export default WorkerActionTypes
