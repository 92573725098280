enum SignatureTypes {
    FETCH_SIGNATURES = 'FETCH_SIGNATURES',
    FETCH_SIGNATURE_BY_ID = 'FETCH_SIGNATURE_BY_ID',
    FETCH_NORMALIZED_CONTRACT_SIGNATURES = 'FETCH_NORMALIZED_CONTRACT_SIGNATURES',
    FETCH_NORMALIZED_SIGNABLE_SIGNATURES = 'FETCH_NORMALIZED_SIGNABLE_SIGNATURES',
    UPDATE_SIGNATURE = 'UPDATE_SIGNATURE',
    SET_IS_LOADING_SIGNATURES = 'SET_IS_LOADING_SIGNATURES',
    SET_IS_LOADING_NORMALIZED_CONTRACT_SIGNATURES = 'SET_IS_LOADING_NORMALIZED_CONTRACT_SIGNATURES',
    SET_IS_LOADING_UPDATE_SIGNATURE = 'SET_IS_LOADING_UPDATE_SIGNATURE',
}
export default SignatureTypes
