import { IQueryParams } from 'npm-medgo-query'
import AxiosInstance from '../../src/modules/axios/axios.instance'
import { PossibleApis, PossibleFetchs } from '../constants/Api.constants'
import generateParams from '../url/customParamGenerator'

export function fetchByIdFactory<T>(
    modalName: PossibleFetchs,
    api = PossibleApis.serviceApi,
): (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T> {
    return async (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {})
        const res = await AxiosInstance.get(
            api + '/api/v1/'.concat(modalName, '/', id.toString(), '?', generateParams(params)),
        )
        return res.data && res.data[0] ? res.data[0] : res.data
    }
}

export function fetchByIdSubFieldFactory<T>(
    modalName: PossibleFetchs,
    api = PossibleApis.serviceApi,
): (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams) => Promise<T> {
    return async (id: number, defaultParams: IQueryParams, queryParams?: IQueryParams): Promise<T> => {
        const params = Object.assign({}, defaultParams, queryParams ? queryParams : {})
        const res = await AxiosInstance.get(
            api + '/api/v1/'.concat(modalName.replace(':id', id.toString()), '?', generateParams(params)),
        )
        return res.data
    }
}

export function fetchByIdFromMonorepo<T>(
    modalName: PossibleFetchs,
    api = PossibleApis.serviceApi,
): (id: number) => Promise<T> {
    return async (id: number): Promise<T> => {
        const res = await AxiosInstance.get(api + '/' + modalName.replace(':id', id.toString()))
        return res.data
    }
}
