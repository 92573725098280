import { IQueryParams } from 'npm-medgo-query'
import { ILevel } from '../../Models/Level'
import { PossibleApis, PossibleFetchs } from '../../modules/constants/Api.constants'
import { postFetchFactory } from '../../modules/factory/postFetch.factory'

const LevelDefaultSelect = [
    'id',
    'name',
    'isAllowedToSeeCustomStatistics',
    'isAllowedToSeeProInstitutionConfigPage',
    'isAllowedToSeeProInstitutionPage',
    'isAllowedToSeeProNotificationHistory',
    'isPostingMissionAllowed',
    'isAllowedToPostMedgoStandardMission',
    'isDeclaringPastMissionAllowed',
    'isSelectingPotentialWorkerMissionAllowed',
    'isCancelingMissionAllowed',
    'isModifyingMissionAllowed',
    'isAllowedToRequestMission',
    'isAllowedToValidateMissionRequestWithoutWorker',
    'isAllowedToSeeRequestedMission',
    'isAllowedToValidateMission',
    'isAllowedToCancelMissionAfterValidation',
    'isPlanningMissionAllowed',
    'isAllowedToSeeProVacatairePage',
    'isAllowedToSeeWorker',
    'isAllowedToSeeWorkerContactInfo',
    'isAllowedToSeeWorkerProfile',
    'isAllowedToSeeMissionHistory',
    'isAllowedToSeeWorkerContactBeforeMissionIsProvided',
    'isAllowedToSeeWorkerContactAfterMissionIsProvided',
    'isInvitingNewWorkerAllowed',
    'isRemovingOrAddingWorkerFromNetworkAllowed',
    'isAllowedToAddressWorkerToJoinNetwork',
    'isAllowedToAddressWorkerNewJobSpecialty',
    'isAllowedToChangeWorkerJob',
    'isAllowedToChangeWorkerService',
    'isAllowedToRequestInterimAgencyOrMarketplace',
    'isAllowedToSeeWorkerMarketplaceProfile',
    'isAllowedToChangeService',
    'isAllowedToSeeMissionOutsideService',
    'isAllowedToSeeStatisticsPage',
    'isAllowedToSaveOnWorkerJobPage',
    'isAllowedToSaveServiceUndefinedForWorkerStatutZero',
    'isAllowedToAddressWorkerStatutOne',
    'isAllowedToValidateMissionRequestWithoutWorkerLevel2: false',
    'isAllowedToValidateMissionRequestWithWorker',
    'isAllowedToValidateMissionRequestWithWorkerLevel2',
    'isAllowedToChooseReplacementForMission',
    'hasContractPermissions',
]

export async function fetchLevels(query: IQueryParams): Promise<ILevel[]> {
    const levels = await postFetchFactory<ILevel>(PossibleFetchs.level, PossibleApis.serviceApi, 2)(
        {
            select: LevelDefaultSelect,
        },
        query,
    )
    return levels
}
