import { IReduxAction } from '../store.types'
import InstitutionTypes from '../types/institution.types'

const initialState: any = {
    institutions: [],
    institution: {},
    institutionsOptions: {},
}
export default function institutionsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionTypes.FETCH_INSTITUTIONS:
            return {
                ...state,
                institutions: action.payload,
            }
        case InstitutionTypes.FETCH_INSTITUTION:
            return {
                ...state,
                institution: action.payload,
            }
        case InstitutionTypes.FETCH_INSTITUTION_OPTIONS:
            return {
                ...state,
                institutionsOptions: {
                    ...state.institutionsOptions,
                    [action.payload.institution]: action.payload,
                },
            }
        case InstitutionTypes.SET_IS_LOADING_FETCH_INSTITUTIONS:
            return {
                ...state,
                isLoadingFetchInstitutions: action.payload,
            }
        case InstitutionTypes.SET_IS_LOADING_FETCH_INSTITUTION_BY_ID:
            return {
                ...state,
                isLoadingFetchInstitution: action.payload,
            }
        case InstitutionTypes.SET_IS_LOADING_FETCH_INSTITUTION_OPTIONS:
            return {
                ...state,
                isLoadingFetchInstitutionOptions: action.payload,
            }
        default:
            return state
    }
}
