import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import SignatureCantSign from './SignatureCantSign.container'
import SignatureNotFoundContainer from './SignatureNotFound.container'
import SignatureReadyToSign from './SignatureReadyToSign.container'

export default class SignatureBase extends React.Component<RouteComponentProps> {
    render(): JSX.Element {
        return (
            <Switch>
                <Route
                    path={this.props.match.path + '/connective-worker-signature-not-found'}
                    component={SignatureNotFoundContainer}
                />
                <Route
                    path={this.props.match.path + '/:idContract/connective-worker-signature-status'}
                    component={SignatureCantSign}
                />
                <Route path={this.props.match.path + '/:idContract/ready-to-sign'} component={SignatureReadyToSign} />
            </Switch>
        )
    }
}
