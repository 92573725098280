import { fetchMe } from '../../services/auth/user.service'
import { IReduxAction } from '../store.types'
import AuthTypes from '../types/auth.types'

export function setIsLoadingFetchMeAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AuthTypes.IS_LOADING_FETCH_ME,
            payload: bool,
        })
    }
}
export function setTokenAction(token: string, ssoAccessToken = ''): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AuthTypes.SET_TOKEN,
            payload: {
                token,
                ssoAccessToken,
            },
        })
    }
}

export function fetchMeAction(): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const user = await fetchMe()
        dispatch({
            type: AuthTypes.FETCH_ME,
            payload: {
                ...user,
                permissions: user.permissions || {},
            },
        })
    }
}
