import { IReduxAction } from '../store.types'
import ServiceActionTypes from '../types/service.types'

const initialState: any = {
    normalizedServices: {},
}
export default function servicesReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case ServiceActionTypes.FETCH_NORMALIZED_SERVICES:
            return {
                ...state,
                normalizedServices: action.payload,
            }

        default:
            return state
    }
}
