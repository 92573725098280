import { IQueryParams } from 'npm-medgo-query'
import { IInstitutionGroup } from '../../../Models/InstitutionGroup'
import { PossibleApis, PossibleFetchs } from '../../../modules/constants/Api.constants'
import { postFetchFactory } from '../../../modules/factory/postFetch.factory'

const InstitutionGroupDefaultSelect = ['id', 'institution']

export async function fetchInstitutionGroups(query: IQueryParams): Promise<IInstitutionGroup[]> {
    const institutionGroups = await postFetchFactory<IInstitutionGroup>(
        PossibleFetchs.institutionGroup,
        PossibleApis.serviceApi,
        2,
    )(
        {
            select: InstitutionGroupDefaultSelect,
        },
        query,
    )
    return institutionGroups
}
