import { IContract, IContractData } from '../../../Models/Contract'
import { PossibleApis, PossibleFetchs } from '../../../modules/constants/Api.constants'
import { fetchFactory } from '../../../modules/factory/fetch.factory'

export async function fetchContractDataByIdMission(idMissions: number[]): Promise<IContractData> {
    return fetchFactory<IContract>(PossibleFetchs.contractsMission, PossibleApis.serviceApi)(
        {
            select: [],
        },
        {},
        {
            idMissions,
        },
    )
}
