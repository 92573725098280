import { EAvailableLang, FooterComponent, ILabelHref, NavbarComponent } from '@hublo/legacy-components'
import jwt from 'jsonwebtoken'
import { Constants, LangToolbox } from 'npm-hublo-toolbox'
import { IQueryParams } from 'npm-medgo-query'
import { Url } from 'npm-medgo-toolbox'
import React from 'react'
import { connect } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { isNullOrUndefined } from 'util'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import loadable from '@loadable/component'
import { setupAxeptio } from 'npm-hublo-analytics'

import { IAdmin } from '../Models/Admin'
import { IInstitution, IInstitutionOptions } from '../Models/Institution'
import { INormalizedLevels } from '../Models/Level'
import { firstLetterUppercase } from '../modules/strings/case'
import { SlowDownBanner } from './components/Shared/SlowDownBanner'
import AdminContainer from './containers/admin/Admin.container'
import DemoBase from './containers/demo/Demo.base'
import SignatureBase from './containers/signatures/Signature.base'
import {
    FeatureTogglesFlagsContext,
    withFeatureTogglesConsumer,
} from './context/feature-toggles/FeatureToggles.context'
import LoadingFallback from './Loading'
import { decodeToken } from './modules/authorization'
import { handleSSOAdminIfNeeded } from './modules/authorization/sso'
import { adminLogout } from './modules/logout'
import { setIsAppReady } from './store/actions/app.actions'
import { fetchMeAction, setTokenAction } from './store/actions/auth.actions'
import {
    fetchInstitutionOptionsByIdInstitutionAction,
    fetchInstitutionsAction,
} from './store/actions/institutions.actions'
import { setLangAction } from './store/actions/lang.actions'
import { fetchLevelsAction } from './store/actions/level.actions'
import { AvailableLangs } from './store/reducers/lang.reducer'
import { isAllowedTo } from './modules/authorization/permission'
import { Permission } from './types/permission'
import setupZendeskWidget from './modules/zendesk'

const ContractsBase = loadable(() => import('./containers/Contracts.base'), {
    fallback: LoadingFallback,
})

interface IReduxPropsApp {
    me: IAdmin
    lang: any
    currentLang: string
    isLoadingApp: boolean
    institutions: IInstitution[]
    institutionsOptions: IInstitutionOptions
    level: INormalizedLevels
}

interface IReduxActionsApp {
    fetchMeAction: () => any
    setLangAction: (lang: string) => any
    setIsAppReady: (bool: boolean) => any
    setTokenAction: (token: string, ssoAccessToken: string) => any
    fetchInstitutionsAction: (param: IQueryParams) => any
    fetchInstitutionOptionsByIdInstitutionAction: (id: number) => any
    fetchLevelsAction: (param: IQueryParams) => any
}

interface IMenuItem {
    label: any
    href: string
    isActive?: boolean
}

interface IPropsApp extends IReduxPropsApp, IReduxActionsApp {
    ldClient?: FeatureTogglesFlagsContext
}

interface IStateProps {
    alreadyIdentify: boolean
    indentifyInProgress: boolean
    closedSlowDownBanner: boolean
}

class App extends React.Component<IPropsApp, IStateProps> {
    constructor(props: IPropsApp) {
        super(props)
        this.logout = this.logout.bind(this)

        if (window.location.pathname.indexOf('/signature') === -1) {
            Url.checkAndSaveTokensFromUrl()
            Url.removeTokensFromUrl(window.location.href)
            const token = localStorage.getItem('token') as string
            const ssoAccessToken = localStorage.getItem('SSOAccessToken') as string
            const decoded: any = jwt.decode(token) // fixme: should use decodeToken
            const lang = decoded ? LangToolbox.getLangStringFromId(decoded.idLanguage) : AvailableLangs.fr
            this.props.setTokenAction(token ? token : '', ssoAccessToken ? ssoAccessToken : '')
            this.props.setLangAction(lang ? lang : AvailableLangs.fr)
            this.props
                .fetchMeAction()
                .then(
                    async (): Promise<void> => {
                        try {
                            this.props.fetchLevelsAction({
                                query: { id: this.props.me && this.props.me.level ? this.props.me.level : -1 },
                                limit: 10,
                                orderby: [{ columnName: 'id', orientation: 'ASC' }],
                            })
                        } catch (err) {
                            console.error(err)
                            window.location.href = process.env.REACT_APP_MEDGO_URL + '/login'
                        }
                    },
                )
                .then((): void => {
                    this.props.setIsAppReady(false)
                })
                .catch((err: any): void => {
                    console.error(err)
                    window.location.href = process.env.REACT_APP_MEDGO_URL + '/login'
                })
            this.props
                .fetchInstitutionsAction({ limit: 100 })
                .then(
                    async (): Promise<void> => {
                        try {
                            this.props.institutions.forEach(
                                async (institution: IInstitution): Promise<void> => {
                                    await this.props.fetchInstitutionOptionsByIdInstitutionAction(
                                        institution.id ? institution.id : -1,
                                    )
                                },
                            )
                        } catch (err) {
                            console.error(err)
                        }
                    },
                )
                .catch((err: any): void => {
                    console.error(err)
                    window.location.href = process.env.REACT_APP_MEDGO_URL + '/login'
                })
        } else {
            this.props.setIsAppReady(false)
        }

        this.state = { alreadyIdentify: false, indentifyInProgress: false, closedSlowDownBanner: false }
    }

    async logout(): Promise<void> {
        const token = localStorage.getItem('token')
        if (token && decodeToken(token)) {
            return adminLogout()
        }
        return adminLogout(false, true)
    }

    async componentDidMount(): Promise<void> {
        // handle SSO admins
        await handleSSOAdminIfNeeded()

        // setup axeptio
        setupAxeptio()

        // setup zendesk
        if (this.props.currentLang === AvailableLangs.fr) {
            setupZendeskWidget()
        }
    }

    componentDidUpdate(prevProps: IPropsApp): void {
        // re-setup axeptio and zendesk on language change
        if (prevProps.currentLang !== this.props.currentLang) {
            // setup axeptio
            setupAxeptio()

            // setup zendesk
            if (this.props.currentLang === AvailableLangs.fr) {
                setupZendeskWidget()
            }
        }
    }

    async UNSAFE_componentWillReceiveProps(): Promise<void> {
        if (
            !this.state.indentifyInProgress &&
            !this.state.alreadyIdentify &&
            this.props.ldClient &&
            this.props.me.id &&
            this.props.me.email &&
            this.props.institutions.length
        ) {
            this.setState({ indentifyInProgress: true })
            this.setState({ alreadyIdentify: true, indentifyInProgress: false })
        }
    }

    generateTokensString(): string {
        const token = localStorage.getItem('token')
        const ssoAccessToken = localStorage.getItem('SSOAccessToken')
        let res = token && token !== '' ? `?token=${token}` : null
        if (ssoAccessToken && ssoAccessToken !== '') {
            res = `${res ? `${res}&` : '?'}SSOAccessToken=${ssoAccessToken}`
        }
        return res ?? ''
    }

    render(): JSX.Element {
        const contactDetails = Constants.companyContactDetailsFor(this.props.currentLang)
        const SSOAccessToken = localStorage.getItem('SSOAccessToken')
        const ssoAccesTokenUrlParam = SSOAccessToken ? `&SSOAccessToken=${SSOAccessToken}` : ''
        const JWTToken = localStorage.getItem('token')
        const tokenUrlParam = JWTToken ? `?token=${JWTToken}${SSOAccessToken ? '&' : ''}` : ''
        const tokensString = this.generateTokensString()
        const displayName = this.props.me
            ? `${this.props.me.firstName ? this.props.me.firstName : ''} ${
                  this.props.me.lastName ? this.props.me.lastName : ''
              }`
            : ''

        const dropdownItems: ILabelHref[] = [
            {
                label: firstLetterUppercase(this.props.lang.profile),
                href: process.env.REACT_APP_MONOREPO_BASE_URL?.concat('/me/profile') ?? '/',
            },
        ]

        const canSeeProInstitutionPage = isAllowedTo({
            permission: Permission.SEE_PRO_INSTITUTION_PAGE,
            permissions: this.props.me.permissions,
            institutionIds: this.props.me.institutionIds,
        })
        if (canSeeProInstitutionPage) {
            dropdownItems.push({
                label: firstLetterUppercase(this.props.lang.myInstitutions),
                href: process.env.REACT_APP_SERVICE_BACKOFFICE_URL
                    ? `${process.env.REACT_APP_SERVICE_BACKOFFICE_URL}/me/institutions${tokenUrlParam}${ssoAccesTokenUrlParam}`
                    : '/',
            })
        }

        dropdownItems.push({
            label: firstLetterUppercase(this.props.lang.myNotifications),
            href: process.env.REACT_APP_SERVICE_BACKOFFICE_URL
                ? `${process.env.REACT_APP_SERVICE_BACKOFFICE_URL}/me/notifications${tokenUrlParam}${ssoAccesTokenUrlParam}`
                : '/',
        })

        const canSeeProInstitutionConfigPage = isAllowedTo({
            permission: Permission.SEE_PRO_INSTITUTION_CONFIG_PAGE,
            permissions: this.props.me.permissions,
            institutionIds: this.props.me.institutionIds,
        })
        if (canSeeProInstitutionConfigPage) {
            dropdownItems.push({
                label: firstLetterUppercase(`${this.props.lang.myConfig}`),
                href: process.env.REACT_APP_SERVICE_BACKOFFICE_URL
                    ? process.env.REACT_APP_SERVICE_BACKOFFICE_URL.concat(tokensString)
                    : '/',
            })
            dropdownItems.push({
                label: 'Go to URL',
                onClick: (): void => {
                    const url = prompt('Write your link below :', 'https://app.hubpreprod.com/')
                    if (url) {
                        window.location.href = url
                    }
                },
            })
        }

        dropdownItems.push({
            label: firstLetterUppercase(this.props.lang.logout),
            onClick: this.logout,
        })

        let menuItems: IMenuItem[] = []
        const idInstitutions = this.props.institutions.map((institution: IInstitution): number => {
            if (institution.id) return institution.id
            else return 0
        })
        if (
            idInstitutions.length === Object.keys(this.props.institutionsOptions).length &&
            Object.keys(this.props.institutionsOptions).length !== 0
        ) {
            menuItems = [
                {
                    label: this.props.lang.proMissions,
                    href: process.env.REACT_APP_MEDGO_URL
                        ? process.env.REACT_APP_MEDGO_URL.concat('/pro/missions2')
                        : '/',
                },
                {
                    label: this.props.lang.proVacataires,
                    href: process.env.REACT_APP_MEDGO_URL
                        ? process.env.REACT_APP_MEDGO_URL.concat('/pro/vacataires2')
                        : '/',
                },
            ]

            if (this.props.ldClient?.flags?.useRecruitmentListing) {
                menuItems.push({
                    label: this.props.lang.recruitment,
                    href: process.env.REACT_APP_MEDGO_URL
                        ? process.env.REACT_APP_MEDGO_URL.concat('/hr-management/pending')
                        : '/',
                })
            }

            const institutionsHasContract = idInstitutions.map((idInstitution: number): boolean => {
                return this.props.institutionsOptions[idInstitution].hasContract
            })
            const isDemoContractAllowed = institutionsHasContract.indexOf(true) === -1 ? true : false
            if (isDemoContractAllowed && this.props.currentLang === 'fr') {
                menuItems.push({
                    label: this.props.lang.contractDemo,
                    href: '/demo',
                    isActive: true,
                })
            } else if (!isDemoContractAllowed) {
                menuItems.push({
                    label: this.props.lang.proContracts,
                    href: '/',
                    isActive: true,
                })
            }
            if (
                !isNullOrUndefined(this.props.me) &&
                !isNullOrUndefined(this.props.me.permissions) &&
                !isNullOrUndefined(this.props.me.institutionIds) &&
                isAllowedTo({
                    permission: Permission.SEE_STATISTICS_PAGE,
                    permissions: this.props.me.permissions,
                    institutionIds: this.props.me.institutionIds,
                })
            ) {
                menuItems.push({
                    label: this.props.lang.dashboard,
                    href: process.env.REACT_APP_DASHBOARD_URL
                        ? `${process.env.REACT_APP_DASHBOARD_URL}/${tokensString}`
                        : '/',
                })
            }
        }
        const isDemoPresent = window.location.href.indexOf('demo') !== -1 ? true : false
        let langWhitelist = undefined
        if (this.props.ldClient?.flags?.disableSpanishLang === true) {
            langWhitelist = [1, 2, 3]
        }
        if (this.props.ldClient?.flags?.usePlatformSlowdownBanner === false) {
            localStorage.removeItem('showSlownessBanner')
        }

        const showBanner = localStorage.getItem('showSlownessBanner') !== 'false' && !this.state.closedSlowDownBanner
        const showSlowDownBanner = showBanner && this.props.ldClient?.flags?.usePlatformSlowdownBanner
        const showContractsSlowdownBanner = showBanner && this.props.ldClient?.flags?.useContractsSlowdownBanner
        return (
            <div className="App">
                <Router>
                    <div>
                        {(showSlowDownBanner || showContractsSlowdownBanner) && (
                            <SlowDownBanner
                                onClickClose={() => {
                                    this.setState({ closedSlowDownBanner: true })
                                    localStorage.setItem('showSlownessBanner', 'false')
                                }}
                                text={
                                    showSlowDownBanner
                                        ? this.props.lang.maintenanceBanner
                                        : this.props.lang.contractsMaintenanceBanner
                                }
                            />
                        )}
                        {this.props.me && this.props.me.level && this.props.me.level > 0 ? (
                            <NavbarComponent
                                logout={this.logout}
                                displayName={displayName}
                                dropdownItems={dropdownItems}
                                menuItems={menuItems}
                            />
                        ) : null}
                        {!this.props.isLoadingApp ? (
                            <Switch>
                                <Route path="/admin" component={AdminContainer} />
                                <Route path="/signature" component={SignatureBase} />
                                <Route path="/demo" component={DemoBase} />
                                <Route path="/" component={ContractsBase} />
                            </Switch>
                        ) : null}
                        {isDemoPresent ? <div /> : <div className="has-padding-top is-large" />}
                        {!this.props.isLoadingApp ? (
                            <FooterComponent
                                lang={{ ...this.props.lang, whitelist: langWhitelist }}
                                currentLang={this.props.currentLang as EAvailableLang}
                                emailText={contactDetails.email}
                                urlBase={process.env.REACT_APP_MEDGO_URL}
                                handleChangeLang={(lang: EAvailableLang): void => {
                                    this.props.setLangAction(lang)
                                }}
                            />
                        ) : null}
                    </div>
                </Router>
            </div>
        )
    }
}

export default connect(
    (centralState: any): IReduxPropsApp => ({
        me: centralState.auth.user,
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        isLoadingApp: centralState.app.isLoadingApp,
        institutions: centralState.institutions.institutions,
        institutionsOptions: centralState.institutions.institutionsOptions,
        level: centralState.level.byId,
    }),
    {
        fetchMeAction,
        setLangAction,
        setIsAppReady,
        setTokenAction,
        fetchInstitutionsAction,
        fetchInstitutionOptionsByIdInstitutionAction,
        fetchLevelsAction,
    },
)(withFeatureTogglesConsumer(App))
