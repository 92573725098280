import { IReduxAction } from '../store.types'
import AppTypes from '../types/app.types'

export function setIsAppReady(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AppTypes.SET_IS_APP_READY,
            payload: bool,
        })
    }
}

export function setIsContractPageReady(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: AppTypes.SET_IS_CONTRACT_PAGE_READY,
            payload: bool,
        })
    }
}
