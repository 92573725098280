import { IQueryParams } from 'npm-medgo-query'
import { ILevel, INormalizedLevels } from '../../../Models/Level'
import { getErrorFromResponse } from '../../../toolbox/store/apiError'
import { fetchLevels } from '../../service/level.service'
import { IReduxAction } from '../store.types'
import LevelTypes from '../types/level.types'

export function fetchLevelsAction(query: IQueryParams): (dispatch: (x: IReduxAction) => void) => void {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        try {
            const levels = await fetchLevels(query)
            dispatch({
                type: LevelTypes.FETCH_LEVELS,
                payload: {
                    byId: levels.reduce((p: INormalizedLevels, c: ILevel): INormalizedLevels => {
                        p[c.id] = c
                        return p
                    }, {}),
                    ids: levels.map((e): number => e.id),
                },
            })
            return
        } catch (err) {
            throw getErrorFromResponse(err)
        }
    }
}
