const setupZendeskWidget = () => {
    if (document.getElementById('ze-snippet')) {
        return
    }

    const zendeskScript = document.createElement('script')
    zendeskScript.setAttribute('type', 'text/javascript')
    zendeskScript.setAttribute('id', 'ze-snippet')
    zendeskScript.setAttribute('async', 'true')
    zendeskScript.setAttribute(
        'src',
        'https://static.zdassets.com/ekr/snippet.js?key=aa09b07e-b446-459a-9f0b-191509a6aba3',
    )
    document.head.appendChild(zendeskScript)
}

export default setupZendeskWidget
