import { IReduxAction } from '../store.types'
import AdminActionTypes from '../types/admin.types'

const initialState: any = {
    admin: {},
    isLoadingAdminById: false,
}

export default function adminsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case AdminActionTypes.SET_IS_LOADING_ADMIN_BY_ID:
            return {
                ...state,
                isLoadingAdminById: action.payload,
            }
        case AdminActionTypes.FETCH_ADMIN:
            return {
                ...state,
                admin: action.payload,
            }
        case AdminActionTypes.SET_ADMIN:
            return {
                ...state,
                admin: action.payload,
            }
        default:
            return state
    }
}
