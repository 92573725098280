export enum PossibleFetchs {
    missions = 'mission',
    contract = 'contract',
    contractForWorker = 'worker/contract',
    institutions = 'institution',
    workers = 'worker',
    motifs = 'missionmotifcreation',
    contractsMission = 'contract/mission',
    institutionOptions = 'institution/:id/options',
    signature = 'signature',
    signatureForWorker = 'worker/signature',
    admin = 'admin',
    missionPeriods = 'missionperiod',
    services = 'service',
    jobs = 'job',
    specialties = 'specialty',
    specialtyCategory = 'specialtycategory',
    template = 'template',
    institutionHasAdmin = 'institution-has-admin/fetch',
    institutionGroup = 'institution-group/fetch',
    signUpCode = 'signup-code/fetch',
    level = 'level/fetch',
    missionHasJob = 'mission-has-job',
    workerInfo = 'worker-info',
    adminInfo = 'admin-info',
    interimAgencyHasInterimAgencyRequest = 'interim-agency-has-interim-agency-request/fetch',
    interimAgency = 'interim-agency/fetch',
    institutionStaff = 'institution-staff?idInstitution=:id',
}

export const PossibleApis = {
    serviceApi: process.env.REACT_APP_SERVICE_API_URL || '',
    serviceApp: process.env.REACT_APP_MEDGO_URL || '',
    serviceContract: process.env.REACT_APP_CONTRACT_URL || '',
    bffAdmin: process.env.REACT_APP_BFF_ADMIN_URL || '',
}

export enum PossibleUpdates {
    contract = 'contract',
    signature = 'signature',
    missionMotif = 'mission/:id/motif',
}

export enum PossiblePost {
    contract = 'contract',
    contractValidation = 'contract/:id/validate',
    contractCancelation = 'contract/:id/cancel',
    contractFusion = 'contract/fuse',
    bulkSignContracts = 'signature/bulk-sign',
    CSMChangeContractToMassESig = 'csm/instituion/:id/change-contracts-to-mass-esig',
    contractRegeneration = 'contract/regenerate-contract-by-id/:id',
    contractsValidation = 'contract/validate-contracts',
}

export function getFileUrlFromFileName(fileName: string): string {
    return `${PossibleApis.serviceContract}/api/v1/aws/${fileName}`
}
