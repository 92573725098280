import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ISignature } from '../../../Models/Signature'
import { fetchSignatureByIdAction } from '../../store/actions/signatures.actions'

interface IReduxPropsSignatureCantSign {
    signature: ISignature
    lang: any
}
interface IReduxActionsSignatureCantSign {
    fetchSignatureByIdAction: (id: number) => Promise<void>
}

class SignatureCantSign extends React.Component<
    IReduxActionsSignatureCantSign & IReduxPropsSignatureCantSign & RouteComponentProps<{ id: string }>
> {
    render(): JSX.Element {
        return (
            <div className="container">
                <div className="hero is-medium">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4 offset-4">
                                    <img className="img-fluid w-100" src="/images/contractLogo.png" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <p className="has-margin-top is-large">
                                <strong>{this.props.lang.contractNotFound}</strong>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (centralState: any): IReduxPropsSignatureCantSign => ({
        signature: centralState.signatures.signature,
        lang: centralState.language.lang,
    }),
    { fetchSignatureByIdAction },
)(SignatureCantSign)
