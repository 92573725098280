import { IQueryParams } from 'npm-medgo-query'
import { ISignUpCode } from '../../../Models/SignUpCode'
import { PossibleApis, PossibleFetchs } from '../../../modules/constants/Api.constants'
import { postFetchFactory } from '../../../modules/factory/postFetch.factory'

const SignUpCodeDefaultSelect = ['id', 'code', 'institutions']

export async function fetchSignUpCodes(query: IQueryParams): Promise<ISignUpCode[]> {
    const signupCodes = await postFetchFactory<ISignUpCode>(PossibleFetchs.signUpCode, PossibleApis.serviceApi, 2)(
        {
            select: SignUpCodeDefaultSelect,
        },
        query,
    )
    return signupCodes
}
