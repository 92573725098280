import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { IAdmin } from '../../../Models/Admin'
import { changeContractsToMassESig } from '../../services/medgoAdmin/medgoAdmin.service'
import { IInstitution } from '../../../Models/Institution'
import {
    fetchInstitutionsAction,
    fetchInstitutionOptionsByIdInstitutionAction,
} from '../../store/actions/institutions.actions'

async function handleClickActivation(
    idInstitution: number,
    setter: (boolean: boolean) => void,
    medgoAccessToken: string,
): Promise<void> {
    setter(true)
    try {
        await changeContractsToMassESig(idInstitution, medgoAccessToken)
    } catch (err) {
        console.error(err)
    }
    setter(false)
}
function redirectToHome(history: any): void {
    history.push('/')
}
export default function AdminContainer({ history }: RouteComponentProps): JSX.Element {
    const me = useSelector((centralState: any): IAdmin => centralState.auth.user)
    if (me.level !== 1) {
        redirectToHome(history)
    }

    const institutions = useSelector((centralState: any): IInstitution[] => centralState.institutions.institutions)
    const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(true)
    const [isLoadingInstitutionOptions, setIsLoadingInstitutionOptions] = useState(true)
    const [isLoadingActivation, setIsLoadingActivation] = useState(false)
    const [medgoToken, setMedgoToken] = useState('')

    const dispatch = useDispatch()
    useEffect((): void => {
        if (institutions.length === 0) {
            fetchInstitutionsAction({ limit: 100 })(dispatch).then((): void => {
                setIsLoadingInstitutions(false)
            })
        }
    })
    useEffect((): void => {
        institutions.forEach((e: IInstitution): void => {
            fetchInstitutionOptionsByIdInstitutionAction(e.id ? e.id : -1)(dispatch).then((): void => {
                setIsLoadingInstitutionOptions(false)
            })
        })
    }, [institutions])
    return isLoadingInstitutions === false && isLoadingInstitutionOptions === false ? (
        <div className="container">
            <div className="jumbotron text-center" style={{ marginTop: '48px' }}>
                <h1>Medgo Admin Section</h1>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label> MEDGO ACCESS TOKEN </label>
                            <input
                                className="form-control"
                                value={medgoToken}
                                onChange={(event): void => setMedgoToken(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-9">
                                <p>Contracts that have not been signed by the admins can be converted to mass ESig</p>
                            </div>
                            <div className="col-3">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    disabled={isLoadingActivation}
                                    onClick={(): void => {
                                        handleClickActivation(
                                            institutions[0].id ? institutions[0].id : -1,
                                            setIsLoadingActivation,
                                            medgoToken,
                                        )
                                    }}
                                >
                                    Change Signatures to Mass ESig
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <span />
    )
}
