import { IQueryParams } from 'npm-medgo-query'
import { fetchInstitutionOptionByIdInstitution, fetchInstitutions } from '../../services/institution/institutions'
import { IReduxAction } from '../store.types'
import InstitutionTypes from '../types/institution.types'

export function setIsLoadingFetchInstitutionsAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_IS_LOADING_FETCH_INSTITUTIONS,
            payload: bool,
        })
    }
}

export function fetchInstitutionsAction(
    param: IQueryParams = {},
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const institutions = await fetchInstitutions(param)
        dispatch({
            type: InstitutionTypes.FETCH_INSTITUTIONS,
            payload: institutions,
        })
    }
}

export function setIsLoadingFetchInstitutionOptionsAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: InstitutionTypes.SET_IS_LOADING_FETCH_INSTITUTION_OPTIONS,
            payload: bool,
        })
    }
}

export function fetchInstitutionOptionsByIdInstitutionAction(
    id: number,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const institutionOption = await fetchInstitutionOptionByIdInstitution(id)
        dispatch({
            type: InstitutionTypes.FETCH_INSTITUTION_OPTIONS,
            payload: Object.assign({}, institutionOption, { institution: id }),
        })
    }
}
