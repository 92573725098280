enum ContractActionTypes {
    SET_CONTRACT = 'SET_CONTRACT',
    FETCH_CONTRACTS = 'FETCH_CONTRACTS',
    FETCH_CONTRACTS_COUNT = 'FETCH_CONTRACTS_COUNT',
    SET_IS_LOADING_CONTRACTS_COUNT = 'SET_IS_LOADING_CONTRACTS_COUNT',
    FETCH_CONTRACT = 'FETCH_CONTRACT',
    FETCH_CONTRACT_DATA_BY_ID_MISSIONS = 'FETCH_CONTRACT_DATA_BY_ID_MISSIONS',
    FETCH_SIGNABLE_CONTRACT = 'FETCH_SIGNABLE_CONTRACT',
    SET_SIGNABLE_CONTRACT = 'SET_SIGNABLE_CONTRACT',
    SET_IS_CONTRACT_RESUME_MODAL_DISPLAYED = 'SET_IS_CONTRACT_RESUME_MODAL_DISPLAYED',
    SET_IS_CONTRACT_VISUALISE_MODAL_DISPLAYED = 'SET_IS_CONTRACT_VISUALISE_MODAL_DISPLAYED',
    SET_IS_CONTRACT_VALIDATION_MODAL_DISPLAYED = 'SET_IS_CONTRACT_VALIDATION_MODAL_DISPLAYED',
    SET_IS_CONTRACT_VALIDATION_SUCCESS_MODAL_DISPLAYED = 'SET_IS_CONTRACT_VALIDATION_SUCCESS_MODAL_DISPLAYED',
    SET_CONTRACT_MISSING_INFO = 'SET_CONTRACT_MISSING_INFO',
    UPDATE_CONTRACT = 'UPDATE_CONTRACT',
    VALIDATE_CONTRACT = 'VALIDATE_CONTRACT',
    SET_IS_CONTRACT_FUSION_MODAL_DISPLAYED = 'SET_IS_CONTRACT_FUSION_MODAL_DISPLAYED',
    SET_NORMALIZED_SELECTED_CONTRACTS = 'SET_NORMALIZED_SELECTED_CONTRACTS',
    FETCH_NORMALIZED_CONTRACTS = 'FETCH_NORMALIZED_CONTRACTS',
    SET_MOTIF_COMPLEMENTARY_STING = 'SET_MOTIF_COMPLEMENTARY_STING',
    SET_MOTIF_MATRICULE = 'SET_MOTIF_MATRICULE',
    SET_CONTRACT_ERROR_MESSAGE = 'SET_CONTRACT_ERROR_MESSAGE',
    FUSE_CONTRACTS = 'FUSE_CONTRACTS',
    SET_CONTRACTS_FUSE_CONFIRMATION_MODAL = 'SET_CONTRACTS_FUSE_CONFIRMATION_MODAL',
    SET_SIGN_ALL_MODAL = 'SET_SIGN_ALL_MODAL',
    SET_CONTRACT_SEARCH_INPUT = 'SET_CONTRACT_SEARCH_INPUT',
    SET_CONTRACT_CANCEL_MODAL = 'SET_CONTRACT_CANCEL_MODAL',
    CANCEL_CONTRACT = 'CANCEL_CONTRACT',
    SET_CONTRACT_BEGIN_AT_FILTER = 'SET_CONTRACT_BEGIN_AT_FILTER',
    SET_CONTRACT_END_AT_FILTER = 'SET_CONTRACT_END_AT_FILTER',
    SET_CONTRACT_STATUS_FILTER = 'SET_CONTRACT_STATUS_FILTER',
    SET_CONTRACT_CANCELLED_OR_FUSED_FILTER = 'SET_CONTRACT_CANCELLED_OR_FUSED_FILTER',
    SET_CONTRACT_INSTITUTION_FILTER = 'SET_CONTRACT_INSTITUTION_FILTER',
    SET_CONTRACT_WORKER_FILTER = 'SET_CONTRACT_WORKER_FILTER',
    SET_IS_LOADING_CONTRACTS = 'SET_IS_LOADING_CONTRACTS',
    SET_IS_LOADING_NORMALIZED_CONTRACT = 'SET_IS_LOADING_NORMALIZED_CONTRACT',
    SET_CONTRACTS = 'SET_CONTRACTS',
    SET_NORMALIZED_CONTRACTS = 'SET_NORMALIZED_CONTRACTS',
    SET_CANT_CANCEL_CONTRACT_MODAL = 'SET_CANT_CANCEL_CONTRACT_MODAL',
    SET_CANT_VALIDATE_CONTRACT_MODAL = 'SET_CANT_VALIDATE_CONTRACT_MODAL',
    SET_CANT_FUSE_CONTRACT_MODAL = 'SET_CANT_FUSE_CONTRACT_MODAL',
    SET_IS_LOADING_CONTRACT_BY_ID = 'SET_IS_LOADING_CONTRACT_BY_ID',
    SET_IS_LOADING_UPDATE_CONTRACT = 'SET_IS_LOADING_UPDATE_CONTRACT',
    SET_IS_LOADING_CONTRACT_DATA = 'SET_IS_LOADING_CONTRACT_DATA',
    SET_IS_LOADING_VALIDATE_CONTRACT = 'SET_IS_LOADING_VALIDATE_CONTRACT',
    SET_IS_LOADING_FUSE_CONTRACTS = 'SET_IS_LOADING_FUSE_CONTRACTS',
    SET_IS_LOADING_CANCEL_CONTRACT = 'SET_IS_LOADING_CANCEL_CONTRACT',
    SET_CONTRACT_WORKER_FILTER_STATE = 'SET_CONTRACT_WORKER_FILTER_STATE',
    SET_CONTRACTS_PAGE = 'SET_CONTRACTS_PAGE',
    SET_NO_CONTRACT = 'SET_NO_CONTRACT',
    SET_LOADING_CONTRACT_ID = 'SET_LOADING_CONTRACT_ID',
    SET_IS_LOADING_CONTRACT_ACTIONS = 'SET_IS_LOADING_CONTRACT_ACTIONS',
    SET_IS_LOADING_CONTRACT_DETAILS = 'SET_IS_LOADING_CONTRACT_DETAILS',
    SET_IS_LOADING_SIGNABLE_CONTRACTS = 'SET_IS_LOADING_SIGNABLE_CONTRACTS',
    SET_SIGN_ALL_CONFIRMATION_MODAL = 'SET_SIGN_ALL_CONFIRMATION_MODAL',
    SET_SIGN_ALL_SUCCESS_MODAL = 'SET_SIGN_ALL_SUCCESS_MODAL',
    SET_IS_LOADING_BULK_SIGN_CONTRACTS = 'SET_IS_LOADING_BULK_SIGN_CONTRACTS',
    BULK_SIGN_CONTRACTS = 'BULK_SIGN_CONTRACTS',
    SET_IS_CONTRACT_VALIDATION_FAIL_MODAL_DISPLAYED = 'SET_IS_CONTRACT_VALIDATION_FAIL_MODAL_DISPLAYED',
    SET_IS_CONTRACTS_VALIDATION_MODAL_DISPLAYED = 'SET_IS_CONTRACTS_VALIDATION_MODAL_DISPLAYED',
    SET_CONTRACTS_VALIDATION_MODAL_INFORMATION = 'SET_CONTRACTS_VALIDATION_MODAL_INFORMATION',
    SET_IS_CONTRACTS_VALIDATION_CONFIRMATION_MODAL_DISPLAYED = 'SET_IS_CONTRACTS_VALIDATION_CONFIRMATION_MODAL_DISPLAYED',
    SET_IS_CONTRACTS_VALIDATION_SUCCESS_MODAL_DISPLAYED = 'SET_IS_CONTRACTS_VALIDATION_SUCCESS_MODAL_DISPLAYED',
    SET_AT_LEAST_ONE_CONTRACT_WAS_SELECTED_TO_BE_VALIDATED = 'SET_AT_LEAST_ONE_CONTRACT_WAS_SELECTED_TO_BE_VALIDATED',
    SET_IS_CONTRACT_DOWNLOAD_ALL_CONFIRMATION_MODAL_DISPLAYED = 'SET_IS_CONTRACT_DOWNLOAD_ALL_CONFIRMATION_MODAL_DISPLAYED',
    SET_IS_CONTRACT_DOWNLOAD_ALL_SUCCESS_MODAL_DISPLAYED = 'SET_IS_CONTRACT_DOWNLOAD_ALL_SUCCESS_MODAL_DISPLAYED',
    SET_IS_CONTRACT_DOWNLOAD_ALL_FAIL_MODAL_DISPLAYED = 'SET_IS_CONTRACT_DOWNLOAD_ALL_FAIL_MODAL_DISPLAYED',
    SET_IS_CONTRACTS_VALIDATION_LOADING = 'SET_IS_CONTRACTS_VALIDATION_LOADING',
    SET_MOTIF_COMPLEMENTARY_STRING_ERROR_MESSAGE = 'SET_MOTIF_COMPLEMENTARY_STRING_ERROR_MESSAGE',
    SET_MOTIF_MATRICULE_ERROR_MESSAGE = 'SET_MOTIF_MATRICULE_ERROR_MESSAGE',
    SET_SELECT_MOTIF_ERROR_MESSAGE = 'SET_SELECT_MOTIF_ERROR_MESSAGE',
}
export default ContractActionTypes
