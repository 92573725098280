import { IReduxAction } from '../store.types'
import SignatureTypes from '../types/signature.types'

const initialState: any = {
    normalizedSignatures: {},
    signature: {},
    normalizedSignableSignatures: {},
    selectedContractNormalizedSignatures: {},
    isLoadingSignatures: false,
    isLoadingNormalizedContractSignatures: false,
    isLoadingUpdateSignatures: false,
}
export default function signatureReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case SignatureTypes.FETCH_SIGNATURES:
            return {
                ...state,
                normalizedSignatures: action.payload,
            }
        case SignatureTypes.FETCH_SIGNATURE_BY_ID:
            return {
                ...state,
                signature: action.payload,
            }
        case SignatureTypes.FETCH_NORMALIZED_SIGNABLE_SIGNATURES:
            return {
                ...state,
                normalizedSignableSignatures: action.payload,
            }
        case SignatureTypes.FETCH_NORMALIZED_CONTRACT_SIGNATURES:
            return {
                ...state,
                selectedContractNormalizedSignatures: action.payload,
            }
        case SignatureTypes.UPDATE_SIGNATURE:
            return {
                ...state,
                normalizedSignatures: {
                    ...state.normalizedSignatures,
                    [action.payload.id]: action.payload,
                },
                selectedContractNormalizedSignatures: {
                    ...state.selectedContractNormalizedSignatures,
                    [action.payload.id]: action.payload,
                },
            }
        case SignatureTypes.SET_IS_LOADING_SIGNATURES:
            return {
                ...state,
                isLoadingSignatures: action.payload,
            }
        case SignatureTypes.SET_IS_LOADING_NORMALIZED_CONTRACT_SIGNATURES:
            return {
                ...state,
                isLoadingNormalizedContractSignatures: action.payload,
            }
        case SignatureTypes.SET_IS_LOADING_UPDATE_SIGNATURE:
            return {
                ...state,
                isLoadingUpdateSignatures: action.payload,
            }
        default:
            return state
    }
}
