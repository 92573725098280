export const isTestingEnvironment = (): boolean => {
    return ['testing', 'test'].includes(process.env.NODE_ENV)
}

export const isDevelopmentEnvironment = (): boolean => {
    return process.env.NODE_ENV === 'development'
}

export const isPreprodEnvironment = (): boolean => {
    // @ts-ignore
    return process.env.NODE_ENV === 'preprod'
}

export const isProductionEnvironment = (): boolean => {
    return process.env.NODE_ENV === 'production'
}

export function isReactProductionEnviroment(): boolean {
    return window.location.hostname.indexOf('hublo.com') !== -1
}

export function isReactDevelopmentEnvironment(): boolean {
    return window.location.hostname.indexOf('hubpreprod.com') !== -1
}
