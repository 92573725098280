import { IReduxAction } from '../store.types'
import MotifTypes from '../types/motif.types'
import InstitutionStaffTypes from '../types/institutionStaff.types'

const initialState: any = {
    motifs: [],
    motif: {},
    isLoadingMotifs: false,
    staff: [],
}
export default function motifsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case MotifTypes.FETCH_MOTIFS:
            return {
                ...state,
                motifs: action.payload,
            }
        case MotifTypes.SET_MOTIF:
            return {
                ...state,
                motif: action.payload,
            }
        case MotifTypes.SET_IS_LOADING_MOTIFS:
            return {
                ...state,
                isLoadingMotifs: action.payload,
            }
        case InstitutionStaffTypes.FETCH_INSTITUTION_STAFF:
            return {
                ...state,
                staff: action.payload,
            }
        default:
            return state
    }
}
