import AxiosInstance from '../../src/modules/axios/axios.instance'
import { PossibleApis, PossiblePost } from '../constants/Api.constants'

export function postFactory<T>(modalName: PossiblePost, api = PossibleApis.serviceApp): (data: T) => Promise<T> {
    return async (data: T): Promise<T> => {
        const res = await AxiosInstance.post(api + '/api/v1/'.concat(modalName), data)
        return res.data
    }
}

export function postByIdSubFieldFactory<T>(
    modalName: PossiblePost,
    api = PossibleApis.serviceApp,
): (id: number, data?: T) => Promise<T> {
    return async (id: number, data?: T): Promise<T> => {
        const res = await AxiosInstance.post(api + '/api/v1/'.concat(modalName.replace(':id', id.toString())), data)
        return res.data
    }
}

export function postByIdAndQueryParamsFactory<T>(
    modalName: PossiblePost,
    api = PossibleApis.serviceApp,
    apiVersion: number = 1,
): (id: number, queryUrlParams?: any) => Promise<T> {
    return async (id: number, queryUrlParams?: any): Promise<T> => {
        const queryUrlKeys = queryUrlParams ? Object.keys(queryUrlParams) : []

        const res = await AxiosInstance.post(
            api +
                `/api/v${apiVersion}/`.concat(
                    modalName.replace(':id', id.toString()),
                    '?',
                    queryUrlKeys.length > 0
                        ? queryUrlKeys.map((e): string => e + '=' + queryUrlParams[e]).join('&')
                        : '',
                ),
        )
        return res.data ? (res.data.data ? res.data.data : res.data) : null
    }
}
