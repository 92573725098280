import { ILevel } from '../../../Models/Level'
import { IReduxAction, IReduxInitialState } from '../store.types'
import LevelTypes from '../types/level.types'

const initialState: IReduxInitialState<ILevel> = {
    byId: {},
    ids: [],
    selectedId: -1,
    count: -1,
    selectedMulti: [],
    isLoading: false,
}

export default function levelReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case LevelTypes.FETCH_LEVELS:
            return { ...state, byId: { ...action.payload.byId }, ids: [...action.payload.ids] }
        default:
            return state
    }
}
