import { IQueryParams } from 'npm-medgo-query'
import { IInstitutionHasAdmin } from '../../../Models/InstitutionHasAdmin'
import { PossibleApis, PossibleFetchs } from '../../../modules/constants/Api.constants'
import { postFetchFactory } from '../../../modules/factory/postFetch.factory'

const AdminInInstitutionDefaultSelect = ['id', 'admin', 'institution']

export async function fetchInstitutionHasAdmins(query: IQueryParams): Promise<IInstitutionHasAdmin[]> {
    const adminInInstitutions = await postFetchFactory<IInstitutionHasAdmin>(
        PossibleFetchs.institutionHasAdmin,
        PossibleApis.serviceApi,
        2,
    )(
        {
            select: AdminInInstitutionDefaultSelect,
        },
        query,
    )
    return adminInInstitutions
}
