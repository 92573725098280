import { IReduxAction } from '../store.types'
import ContractActionTypes from '../types/contract.types'
import moment from 'moment'
import { PossibleWorkerFilterStates } from '../../constants/Filter.constants'

let now: any = moment()
    .clone()
    .subtract(2, 'days')
if (now.weekday() === 6 || now.weekday() === 0) {
    now.subtract(2, 'days')
} else if (now.weekday() === 5 || now.weekday() === 6) {
    now.subtract(1, 'days')
}
now = now.startOf('day').unix()
const initialState: any = {
    contracts: [],
    signableContracts: [],
    contract: {},
    isContractResumeModalDisplayed: false,
    isContractVisualiseModalDisplayed: false,
    isContractValidationModalDisplayed: false,
    isContractFusionModalDisplayed: false,
    isContractsValidationModalDisplayed: false,
    isContractValidationFailedModalDisplayed: false,
    isSignAllConfirmationModalDisplayed: false,
    isSignAllModalDisplayed: false,
    isSignAllSuccessModalDisplayed: false,
    isLoadingSuccessContract: false,
    isContractValidationSuccessModalDisplayed: false,
    contractData: {},
    contractMissingInfo: [],
    normalizedContracts: {},
    normalizedSelectedContracts: {},
    motifComplementaryString: '',
    motifMatricule: '',
    contractErrorMessage: '',
    isContractsFusionModalConfirmationDisplayed: false,
    isContractsValidationConfirmationModalDisplayed: false,
    isContractsValidationSuccessModalDisplayed: false,
    isContractCancelModalDisplayed: false,
    contractFilterBeginAt: now,
    contractFilterEndAt: null,
    contractFilterStatus: [0, 1, 2],
    contractFilterCancelledOrFused: [1],
    contractFilterInstitutions: [],
    contractFilterWorker: -1,
    isLoadingContracts: false,
    isLoadingNormalizedContracts: false,
    isCantCancelContractModalDisplayed: false,
    isCantValidateContractModalDisplayed: false,
    isCantFuseContractModalDisplayed: false,
    isLoadingContractById: false,
    isLoadingUpdateContract: false,
    isLoadingContractData: false,
    isLoadingValidateContract: false,
    isLoadingFuseContracts: false,
    isLoadingCancelContract: false,
    contractFilterWorkerState: PossibleWorkerFilterStates.blur,
    contractsCount: 0,
    isLoadingCountractsCount: false,
    contractsPage: 1,
    noContract: false,
    loadingContractId: -1,
    isLoadingContractDetails: false,
    isLoadingContractActions: false,
    contractsForValidation: [],
    atLeastOneContractWasSelectedToBeValidated: false,
    isContractDownloadAllConfirmationModalDisplayed: false,
    isContractDownloadAllSuccessModalDisplayed: false,
    isContractDownloadAllFailModalDisplayed: false,
    isContractsValidationModalLoading: false,
}
export default function contractsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case ContractActionTypes.FETCH_CONTRACTS:
        case ContractActionTypes.SET_CONTRACTS:
            return {
                ...state,
                contracts: action.payload,
            }
        case ContractActionTypes.FUSE_CONTRACTS:
        case ContractActionTypes.UPDATE_CONTRACT:
        case ContractActionTypes.VALIDATE_CONTRACT:
        case ContractActionTypes.CANCEL_CONTRACT:
        case ContractActionTypes.SET_CONTRACT:
        case ContractActionTypes.FETCH_CONTRACT:
            return {
                ...state,
                contract: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_RESUME_MODAL_DISPLAYED:
            return {
                ...state,
                isContractResumeModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_VISUALISE_MODAL_DISPLAYED:
            return {
                ...state,
                isContractVisualiseModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_VALIDATION_MODAL_DISPLAYED:
            return {
                ...state,
                isContractValidationModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_VALIDATION_SUCCESS_MODAL_DISPLAYED:
            return {
                ...state,
                isContractValidationSuccessModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_VALIDATION_FAIL_MODAL_DISPLAYED:
            return {
                ...state,
                isContractValidationFailedModalDisplayed: action.payload,
            }
        case ContractActionTypes.FETCH_CONTRACT_DATA_BY_ID_MISSIONS:
            return {
                ...state,
                contractData: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_MISSING_INFO:
            return {
                ...state,
                contractMissingInfo: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_FUSION_MODAL_DISPLAYED:
            return {
                ...state,
                isContractFusionModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_NORMALIZED_SELECTED_CONTRACTS:
            return {
                ...state,
                normalizedSelectedContracts: action.payload,
            }
        case ContractActionTypes.FETCH_NORMALIZED_CONTRACTS:
            return {
                ...state,
                normalizedContracts: action.payload,
            }
        case ContractActionTypes.SET_MOTIF_COMPLEMENTARY_STING:
            return {
                ...state,
                motifComplementaryString: action.payload,
            }
        case ContractActionTypes.SET_MOTIF_MATRICULE:
            return {
                ...state,
                motifMatricule: action.payload,
            }
        case ContractActionTypes.SET_SELECT_MOTIF_ERROR_MESSAGE:
            return {
                ...state,
                selectMotifErrorMessage: action.payload,
            }
        case ContractActionTypes.SET_MOTIF_COMPLEMENTARY_STRING_ERROR_MESSAGE:
            return {
                ...state,
                motifWriteComplementaryStringErrorMessage: action.payload,
            }
        case ContractActionTypes.SET_MOTIF_MATRICULE_ERROR_MESSAGE:
            return {
                ...state,
                motifWriteMatriculeErrorMessage: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_ERROR_MESSAGE:
            return {
                ...state,
                contractErrorMessage: action.payload,
            }
        case ContractActionTypes.SET_CONTRACTS_FUSE_CONFIRMATION_MODAL:
            return {
                ...state,
                isContractsFusionModalConfirmationDisplayed: action.payload,
            }
        case ContractActionTypes.SET_SIGN_ALL_MODAL:
            return {
                ...state,
                isSignAllModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_SIGN_ALL_CONFIRMATION_MODAL:
            return {
                ...state,
                isSignAllConfirmationModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_SEARCH_INPUT:
            return {
                ...state,
                contractsSearchInput: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_CANCEL_MODAL:
            return {
                ...state,
                isContractCancelModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_BEGIN_AT_FILTER:
            return {
                ...state,
                contractFilterBeginAt: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_END_AT_FILTER:
            return {
                ...state,
                contractFilterEndAt: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_STATUS_FILTER:
            return {
                ...state,
                contractFilterStatus: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_INSTITUTION_FILTER:
            return {
                ...state,
                contractFilterInstitutions: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_WORKER_FILTER:
            return {
                ...state,
                contractFilterWorker: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_WORKER_FILTER_STATE:
            return {
                ...state,
                contractFilterWorkerState: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_CONTRACTS:
            return {
                ...state,
                isLoadingContracts: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_CONTRACT_BY_ID:
            return {
                ...state,
                isLoadingContractById: action.payload,
            }
        case ContractActionTypes.SET_NORMALIZED_CONTRACTS:
            return {
                ...state,
                normalizedContracts: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_NORMALIZED_CONTRACT:
            return {
                ...state,
                isLoadingNormalizedContracts: action.payload,
            }
        case ContractActionTypes.SET_CANT_CANCEL_CONTRACT_MODAL:
            return {
                ...state,
                isCantCancelContractModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_CANT_VALIDATE_CONTRACT_MODAL:
            return {
                ...state,
                isCantValidateContractModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_CANT_FUSE_CONTRACT_MODAL:
            return {
                ...state,
                isCantFuseContractModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_UPDATE_CONTRACT:
            return {
                ...state,
                isLoadingUpdateContract: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_CONTRACT_DATA:
            return {
                ...state,
                isLoadingContractData: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_VALIDATE_CONTRACT:
            return {
                ...state,
                isLoadingValidateContract: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_FUSE_CONTRACTS:
            return {
                ...state,
                isLoadingFuseContracts: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_CANCEL_CONTRACT:
            return {
                ...state,
                isLoadingCancelContract: action.payload,
            }
        case ContractActionTypes.FETCH_CONTRACTS_COUNT:
            return {
                ...state,
                contractsCount: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_CONTRACTS_COUNT:
            return {
                ...state,
                isLoadingCountractsCount: action.payload,
            }
        case ContractActionTypes.SET_CONTRACTS_PAGE:
            return {
                ...state,
                contractsPage: action.payload,
            }
        case ContractActionTypes.SET_NO_CONTRACT:
            return {
                ...state,
                noContract: action.payload,
            }
        case ContractActionTypes.SET_CONTRACT_CANCELLED_OR_FUSED_FILTER:
            return {
                ...state,
                contractFilterCancelledOrFused: action.payload,
            }
        case ContractActionTypes.SET_LOADING_CONTRACT_ID:
            return {
                ...state,
                loadingContractId: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_CONTRACT_DETAILS:
            return {
                ...state,
                isLoadingContractDetails: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_CONTRACT_ACTIONS:
            return {
                ...state,
                isLoadingContractActions: action.payload,
            }
        case ContractActionTypes.FETCH_SIGNABLE_CONTRACT:
            return {
                ...state,
                signableContracts: action.payload,
            }
        case ContractActionTypes.SET_SIGNABLE_CONTRACT:
            return {
                ...state,
                signableContracts: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_SIGNABLE_CONTRACTS:
            return {
                ...state,
                isLoadingSignableContract: action.payload,
            }
        case ContractActionTypes.SET_SIGN_ALL_SUCCESS_MODAL:
            return {
                ...state,
                isSignAllSuccessModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_LOADING_BULK_SIGN_CONTRACTS:
            return {
                ...state,
                isSignAllSuccessModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_MODAL_DISPLAYED:
            return {
                ...state,
                isContractsValidationModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_CONTRACTS_VALIDATION_MODAL_INFORMATION:
            return {
                ...state,
                contractsForValidation: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_CONFIRMATION_MODAL_DISPLAYED:
            return {
                ...state,
                isContractsValidationConfirmationModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_SUCCESS_MODAL_DISPLAYED:
            return {
                ...state,
                isContractsValidationSuccessModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_AT_LEAST_ONE_CONTRACT_WAS_SELECTED_TO_BE_VALIDATED:
            return {
                ...state,
                atLeastOneContractWasSelectedToBeValidated: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_DOWNLOAD_ALL_CONFIRMATION_MODAL_DISPLAYED:
            return {
                ...state,
                isContractDownloadAllConfirmationModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_DOWNLOAD_ALL_SUCCESS_MODAL_DISPLAYED:
            return {
                ...state,
                isContractDownloadAllSuccessModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACT_DOWNLOAD_ALL_FAIL_MODAL_DISPLAYED:
            return {
                ...state,
                isContractDownloadAllFailModalDisplayed: action.payload,
            }
        case ContractActionTypes.SET_IS_CONTRACTS_VALIDATION_LOADING:
            return {
                ...state,
                isContractsValidationModalLoading: action.payload,
            }
        case ContractActionTypes.BULK_SIGN_CONTRACTS:
        default:
            return state
    }
}
