import { IReduxAction } from '../store.types'
import MissionActionTypes from '../types/mission.types'

const initialState: any = {
    missions: [],
    mission: {},
    normalizedMissions: {},
    normalizedSelectedMissions: {},
    normalizedSignableMissions: {},
    normalizedContractsValidationMissions: {},
    selectedJobId: -1,
    selectedInstitutionId: -1,
    selectedIsFullTime: null,
    selectedServiceId: -1,
    selectedTemplateId: -1,
    isLoadingMissions: false,
    isLoadingMissionById: false,
    isLoadingNormalizedMissions: false,
}
export default function missionsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case MissionActionTypes.FETCH_MISSIONS:
            return {
                ...state,
                missions: action.payload,
            }
        case MissionActionTypes.FETCH_MISSION_BY_ID:
            return {
                ...state,
                mission: action.payload,
            }
        case MissionActionTypes.FETCH_NORMALIZED_SIGNABLE_MISSIONS:
            return {
                ...state,
                normalizedSignableMissions: action.payload,
            }
        case MissionActionTypes.FETCH_NORMALIZED_MISSIONS:
            return {
                ...state,
                normalizedMissions: action.payload,
            }
        case MissionActionTypes.FETCH_NORMALIZED_SELECTED_MISSIONS:
            return {
                ...state,
                normalizedSelectedMissions: action.payload,
            }
        case MissionActionTypes.SET_SELECTED_NORMALIZED_MISSIONS:
            return {
                ...state,
                normalizedMissions: action.payload,
            }
        case MissionActionTypes.SET_SELECTED_JOB_ID:
            return {
                ...state,
                selectedJobId: action.payload,
            }
        case MissionActionTypes.SET_SELECTED_INSTITUTION_ID:
            return {
                ...state,
                selectedInstitutionId: action.payload,
            }
        case MissionActionTypes.SET_SELECTED_TEMPLATE_ID:
            return {
                ...state,
                selectedTemplateId: action.payload,
            }
        case MissionActionTypes.SET_SELECTED_IS_FULL_TIME:
            return {
                ...state,
                selectedIsFullTime: action.payload,
            }
        case MissionActionTypes.SET_SELECTED_SERVICE_ID:
            return {
                ...state,
                selectedServiceId: action.payload,
            }
        case MissionActionTypes.SET_IS_LOADING_MISSIONS:
            return {
                ...state,
                isLoadingMissions: action.payload,
            }
        case MissionActionTypes.SET_IS_LOADING_MISSION_BY_ID:
            return {
                ...state,
                isLoadingMissionById: action.payload,
            }
        case MissionActionTypes.SET_IS_LOADING_NORMALIZED_MISSIONS:
            return {
                ...state,
                isLoadingNormalizedMissions: action.payload,
            }
        case MissionActionTypes.FETCH_NORMALIZED_CONTRACTS_VALIDATION_MISSIONS:
            return {
                ...state,
                normalizedContractsValidationMissions: action.payload,
            }

        default:
            return state
    }
}
