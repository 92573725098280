import { IReduxAction } from '../store.types'
import WorkerActionTypes from '../types/worker.types'

const initialState: any = {
    workers: [],
    worker: {},
    normalizedWorkers: {},
    normalizedSelectedWorkers: {},
    normalizedSignableWorkers: {},
    normalizedContracstValidationWorkers: {},
    isLoadingWorkers: false,
    isLoadingNormalizedWorkers: false,
    isLoadingWorkerById: false,
}
export default function workersReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case WorkerActionTypes.FETCH_WORKERS:
            return {
                ...state,
                workers: action.payload,
            }
        case WorkerActionTypes.FETCH_NORMALIZED_WORKERS:
            return {
                ...state,
                normalizedWorkers: action.payload,
            }
        case WorkerActionTypes.FETCH_NORMALIZED_SIGNABLE_WORKERS:
            return {
                ...state,
                normalizedSignableWorkers: action.payload,
            }
        case WorkerActionTypes.FETCH_WORKER_BY_ID:
            return {
                ...state,
                worker: action.payload,
            }
        case WorkerActionTypes.SET_NORMALIZED_SELECTED_WORKERS:
            return {
                ...state,
                normalizedSelectedWorkers: action.payload,
            }
        case WorkerActionTypes.SET_IS_LOADING_WORKERS:
            return {
                ...state,
                isLoadingWorkers: action.payload,
            }
        case WorkerActionTypes.SET_IS_LOADING_NORMALIZED_WORKERS:
            return {
                ...state,
                isLoadingNormalizedWorkers: action.payload,
            }
        case WorkerActionTypes.SET_IS_LOADING_FETCH_WORKER_BY_ID:
            return {
                ...state,
                isLoadingWorkerById: action.payload,
            }
        case WorkerActionTypes.FETCH_NORMALIZED_CONTRACTS_VALIDATION_WORKERS:
            return {
                ...state,
                normalizedContracstValidationWorkers: action.payload,
            }
        default:
            return state
    }
}
