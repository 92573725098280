import { IReduxAction } from '../store.types'
import SignatureTypes from '../types/signature.types'
import {
    fetchNormalizedSignatures,
    updateSignature,
    fetchSignatureById,
} from '../../services/signature/signatures.service'
import { ISignature } from '../../../Models/Signature'

export function setIsLoadingSignaturesAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SignatureTypes.SET_IS_LOADING_SIGNATURES,
            payload: bool,
        })
    }
}

export function fetchNormalizedSignatureAction(
    contractIds: number[],
    isWorker?: boolean,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const signature = await fetchNormalizedSignatures({ query: { contract: contractIds } }, isWorker)
        dispatch({
            type: SignatureTypes.FETCH_SIGNATURES,
            payload: signature,
        })
    }
}

export function fetchSignatureByIdAction(
    id: number,
    isWorker?: boolean,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const signature = await fetchSignatureById(id, isWorker)
        dispatch({
            type: SignatureTypes.FETCH_SIGNATURE_BY_ID,
            payload: signature,
        })
    }
}

export function fetchNormalizedSignableSignaturesAction(
    idContracts: number[],
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const signatures = await fetchNormalizedSignatures({
            query: { contract: idContracts },
            select: [
                'id',
                'contract',
                'isSigned',
                'type',
                'signedAt',
                'toBeSignedAt',
                'toBeRemindedAt',
                'wasSentToBeSigned',
                'wasSentToBeReminded',
                'isSentToProvider',
                'isRejectedByProvider',
                'signedOnMedgoAt',
                'isActive',
            ],
        })
        dispatch({
            type: SignatureTypes.FETCH_NORMALIZED_SIGNABLE_SIGNATURES,
            payload: signatures,
        })
    }
}

export function setIsLoadingNormalizedContractSignaturesAction(
    bool: boolean,
): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SignatureTypes.SET_IS_LOADING_NORMALIZED_CONTRACT_SIGNATURES,
            payload: bool,
        })
    }
}

export function fetchNormalizedContractSignatureAction(
    contractIds: number[],
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const signatures = await fetchNormalizedSignatures({ query: { contract: contractIds } })
        dispatch({
            type: SignatureTypes.FETCH_NORMALIZED_CONTRACT_SIGNATURES,
            payload: signatures,
        })
    }
}

export function setIsLoadingUpdateSignatureAction(bool: boolean): (dispatch: (x: IReduxAction) => void) => void {
    return (dispatch: (x: IReduxAction) => void): void => {
        dispatch({
            type: SignatureTypes.SET_IS_LOADING_UPDATE_SIGNATURE,
            payload: bool,
        })
    }
}

export function updateSignatureAction(
    id: number,
    newSignature: ISignature,
): (dispatch: (x: IReduxAction) => void) => Promise<void> {
    return async (dispatch: (x: IReduxAction) => void): Promise<void> => {
        const signature = await updateSignature(id, newSignature)
        dispatch({
            type: SignatureTypes.UPDATE_SIGNATURE,
            payload: signature,
        })
    }
}
