import { Url } from 'npm-medgo-toolbox'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { INormalizedSignatures, ISignature } from '../../../Models/Signature'
import { firstLetterUppercase } from '../../../modules/strings/case'
import { fetchNormalizedSignatureAction } from '../../store/actions/signatures.actions'

interface IReduxPropsSignatureCantSign {
    normalizedSignatures: INormalizedSignatures
    lang: any
}
interface IReduxActionsSignatureCantSign {
    fetchNormalizedSignatureAction: (idContracts: number[], isWorker: boolean) => Promise<void>
}

class SignatureReadyToSign extends React.Component<
    IReduxActionsSignatureCantSign & IReduxPropsSignatureCantSign & RouteComponentProps<{ idContract: string }>,
    {
        isLoading: boolean
        signature: ISignature
    }
> {
    constructor(
        props: IReduxActionsSignatureCantSign &
            IReduxPropsSignatureCantSign &
            RouteComponentProps<{ idContract: string }>,
    ) {
        super(props)
        this.state = { isLoading: true, signature: {} }
    }
    async componentDidMount(): Promise<void> {
        Url.checkAndSaveTokensFromUrl()
        this.setState({
            isLoading: true,
        })
        await this.props.fetchNormalizedSignatureAction([parseInt(this.props.match.params.idContract)], true)
        const signature = Object.keys(this.props.normalizedSignatures)
            .map((e: string): ISignature => this.props.normalizedSignatures[parseInt(e)])
            .find((e: ISignature): boolean => e.type === 'worker')

        this.setState({
            isLoading: false,
            signature: signature ? signature : {},
        })
    }
    render(): JSX.Element {
        const { isLoading } = this.state
        return (
            <div className="container">
                <div className="hero is-medium">
                    <div className="row">
                        {isLoading === false ? (
                            <>
                                <div className="col-12 text-center">
                                    <h2 className="mt-4 mb-4 text-primary">
                                        {this.props.lang.oneMoreStepToSignYourContract}
                                    </h2>
                                </div>
                                <div className="col-12 text-center">
                                    <p className="mt-4">{this.props.lang.toSignContractClickdown}</p>
                                </div>
                                <div className="col-12 text-center">
                                    <p className="mt-4">{this.props.lang.contactUsInCaseOfAnIssue}</p>
                                </div>

                                <div className="col-12 text-center">
                                    <a
                                        href={
                                            this.state.signature.actionUrl && this.state.signature.actionUrl[0]
                                                ? this.state.signature.actionUrl[0]
                                                : ''
                                        }
                                        className="btn btn-primary m-4 btn-lg"
                                    >
                                        {firstLetterUppercase(this.props.lang.signMyContract)}
                                    </a>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (centralState: any): IReduxPropsSignatureCantSign => ({
        normalizedSignatures: centralState.signatures.normalizedSignatures,
        lang: centralState.language.lang,
    }),
    { fetchNormalizedSignatureAction },
)(SignatureReadyToSign)
