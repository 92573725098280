import { IReduxAction } from '../store.types'
import InstitutionGroupTypes from '../types/institutionGroup.types'

const initialState: any = {
    institutionGroups: [],
    institutionGroupDict: {},
}
export default function institutionGroupsReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InstitutionGroupTypes.FETCH_INSTITUTION_GROUPS:
            return {
                ...state,
                institutionGroups: action.payload,
            }
        case InstitutionGroupTypes.FETCH_INSTITUTION_GROUP:
            return {
                ...state,
                institutionGroupDict: action.payload,
            }
        case InstitutionGroupTypes.SET_IS_LOADING_FETCH_INSTITUTION_GROUPS:
            return {
                ...state,
                isLoadingFetchInstitutionGroups: action.payload,
            }
        default:
            return state
    }
}
