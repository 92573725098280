import React from 'react'
import { connect } from 'react-redux'
import { IInstitution } from '../../../Models/Institution'
import { IAdmin } from '../../../Models/Admin'
import { frontPage } from 'npm-hublo-analytics'
import { IInstitutionGroup } from '../../../Models/InstitutionGroup'
import { IQueryParams } from 'npm-medgo-query'
import { fetchInstitutionGroupsAction } from '../../store/actions/institutionGroup.actions'
import { fetchInstitutionsAction } from '../../store/actions/institutions.actions'

interface IReduxActionsDemoBase {
    fetchInstitutionGroupsAction: (param: IQueryParams) => any
    fetchInstitutionsAction: (param: IQueryParams) => any
}
interface IReduxPropsDemoBase {
    me: IAdmin
    lang: any
    currentLang: string
    institutions: IInstitution[]
    institutionGroupDict: Record<number, IInstitutionGroup[]>
}
interface IPropsDemoBase extends IReduxPropsDemoBase, IReduxActionsDemoBase {}
class DemoBase extends React.Component<IPropsDemoBase> {
    constructor(props: IPropsDemoBase) {
        super(props)
    }
    async componentDidMount(): Promise<void> {
        await this.props.fetchInstitutionsAction({ limit: 100 })
        await this.props.fetchInstitutionGroupsAction({
            query: {
                type: 1,
                institution: this.props.institutions.map(e => e.id || -1),
            },
        })
        frontPage({
            segmentProperty: {
                idAdmin: this.props.me.id,
                idInstitutions: this.props.institutions.map((e: IInstitution): number => e.id),
                institutionsGroupsGrouping: this.props.institutionGroupDict,
                adminLevel: this.props.me.level,
                typeAdmin: this.props.me.typeAdmin,
                path: '/demo',
            },
            name: 'Contract Upselling Viewed',
        })
    }
    render(): JSX.Element {
        const adminFirstName =
            this.props.me.firstName && this.props.me.firstName.length !== 0 ? this.props.me.firstName : 'noFirstName'
        const adminLastName =
            this.props.me.lastName && this.props.me.lastName.length !== 0 ? this.props.me.lastName : 'noLastName'
        const adminEmail = this.props.me.email && this.props.me.email.length !== 0 ? this.props.me.email : 'noEmail'
        const selectedInstitution = this.props.institutions.sort((a: IInstitution, b: IInstitution): number => {
            if (a.id && b.id) {
                return a.id - b.id
            }
            return 0
        })[0]
        const selectedInstitutionName =
            selectedInstitution && selectedInstitution.name && selectedInstitution.name.length !== 0
                ? selectedInstitution.name.replace(/ /g, '&')
                : 'noInstitutionName'
        const iFrameUrl = process.env.REACT_APP_SERVICE_LANDING
            ? process.env.REACT_APP_SERVICE_LANDING.concat(
                  `/fr/contract/${adminEmail}/${adminFirstName}/${adminLastName}/${selectedInstitutionName}`,
              )
            : ''
        return (
            <div className="demo-base" style={{ height: '100vh' }}>
                <iframe
                    id="upsellContract"
                    title="upsellContract"
                    width="100%"
                    height="100%"
                    src={iFrameUrl}
                    style={{ border: 'none' }}
                />
            </div>
        )
    }
}

export default connect(
    (centralState: any): IReduxPropsDemoBase => ({
        me: centralState.auth.user,
        lang: centralState.language.lang,
        currentLang: centralState.language.currentLang,
        institutions: centralState.institutions.institutions,
        institutionGroupDict: centralState.institutionGroups.institutionGroupDict,
    }),
    { fetchInstitutionGroupsAction, fetchInstitutionsAction },
)(DemoBase)
