export function isMobile(): boolean {
    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true
    } else {
        return false
    }
}

export function isIOS(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('iphone') != -1
}

export function isIE(): boolean {
    // return true;
    // MSIE for IE 10 or below
    // Trident/ for verion 11
    return navigator.appVersion.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') !== -1
}

export function isIE9(): boolean {
    if (!isIE()) {
        return false
    }

    const version = parseFloat(navigator.appVersion.split('MSIE')[1])
    return version <= 9
}
