import { IQueryParams } from 'npm-medgo-query'
import { INormalizedSignatures, ISignature } from '../../../Models/Signature'
import { PossibleApis, PossibleFetchs, PossibleUpdates } from '../../../modules/constants/Api.constants'
import { fetchNormalizedFactory } from '../../../modules/factory/fetch.factory'
import { fetchByIdFactory } from '../../../modules/factory/fetchById.factory'
import { updateFactory } from '../../../modules/factory/update.factory'

export async function fetchNormalizedSignatures(
    params: IQueryParams,
    isWorker?: boolean,
): Promise<INormalizedSignatures> {
    return fetchNormalizedFactory<INormalizedSignatures>(
        isWorker ? PossibleFetchs.signatureForWorker : PossibleFetchs.signature,
        PossibleApis.serviceContract,
    )(
        {
            select: [
                'id',
                'contract',
                'isSigned',
                'type',
                'signedAt',
                'toBeSignedAt',
                'toBeRemindedAt',
                'wasSentToBeSigned',
                'wasSentToBeReminded',
                'isSentToProvider',
                'isRejectedByProvider',
                'signedOnMedgoAt',
                'isActive',
                'actionUrl',
            ],
            limit: 1000,
        },
        params,
    )
}

export async function updateSignature(id: number, newSignature: ISignature): Promise<ISignature> {
    return updateFactory<ISignature>(PossibleUpdates.signature, PossibleApis.serviceContract)(id, newSignature)
}
export async function fetchSignatureById(id: number, isWorker?: boolean): Promise<ISignature> {
    return fetchByIdFactory<ISignature>(
        isWorker ? PossibleFetchs.signatureForWorker : PossibleFetchs.signature,
        PossibleApis.serviceContract,
    )(id, {
        select: [
            'id',
            'contract',
            'isSigned',
            'type',
            'signedAt',
            'toBeSignedAt',
            'toBeRemindedAt',
            'wasSentToBeSigned',
            'wasSentToBeReminded',
            'isSentToProvider',
            'signedOnMedgoAt',
            'isActive',
            'actionUrl',
        ],
        limit: 1000,
    })
}
