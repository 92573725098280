import moment from 'moment'
import { Equality } from 'npm-medgo-toolbox'
import { decodeToken } from '.'
import { fetchInstitutionHasAdmins } from '../../services/InstitutionHasAdmin/institutionHasAdmin.service'
import { fetchSignUpCodes } from '../../services/signUpCode/signUpCode.service'
import {
    isReactDevelopmentEnvironment,
    isReactProductionEnviroment,
} from '../../../server/modules/environment/environment'
import { PRODUCTION_DOMAIN, PRE_PRODUCTION_DOMAIN } from '../../../server/modules/constants/Domain.constant'

function isAdminLoggedInViaSSO(): boolean {
    const SSOAccessTokenInLocalStorage = localStorage.getItem('SSOAccessToken')
    if (SSOAccessTokenInLocalStorage && !Equality.isNullOrUndefined(SSOAccessTokenInLocalStorage)) {
        const decodedToken = decodeToken(SSOAccessTokenInLocalStorage ? SSOAccessTokenInLocalStorage : '')
        if (decodedToken && decodedToken.exp) {
            return true
        }
    }
    return false
}

function needtoLogInViaSSO(): boolean {
    const SSOAccessTokenInLocalStorage = localStorage.getItem('SSOAccessToken')
    if (SSOAccessTokenInLocalStorage && !Equality.isNullOrUndefined(SSOAccessTokenInLocalStorage)) {
        const decodedToken = decodeToken(SSOAccessTokenInLocalStorage)
        if (decodedToken && decodedToken.exp) {
            if (moment().isAfter(moment.unix(decodedToken.exp))) {
                return true
            }
            return false
        }
        return true
    }
    return false
}

async function logInAgainViaSSO(): Promise<void> {
    const currentUrl = window.location.href
    document.cookie = `shouldReturnTo=${currentUrl};domain=${
        isReactProductionEnviroment() ? PRODUCTION_DOMAIN : isReactDevelopmentEnvironment() ? PRE_PRODUCTION_DOMAIN : ''
    };path=/`
    const SSOAccessTokenInLocalStorage = localStorage.getItem('SSOAccessToken')
    if (SSOAccessTokenInLocalStorage) {
        const decodedToken = decodeToken(SSOAccessTokenInLocalStorage)
        if (!decodedToken) {
            return
        }
        const adminInstitutions = await fetchInstitutionHasAdmins({
            query: {
                admin: decodedToken.user.id,
            },
        })
        const idInstitution = adminInstitutions[0].institution
        const signUpCodes = await fetchSignUpCodes({
            query: {
                institutions: idInstitution,
            },
            select: ['code'],
        })
        const code = signUpCodes[0].code
        window.location.href = `${process.env.REACT_APP_SERVICE_AUTH_URL}sso?institutionCode=${code}`
    }
}

export async function handleSSOAdminIfNeeded(): Promise<void> {
    if (!isAdminLoggedInViaSSO()) {
        return
    }
    if (!needtoLogInViaSSO()) {
        return
    }
    await logInAgainViaSSO()
}
