import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import App from './App'
import './scss/index.scss'
import * as serviceWorker from './serviceWorker'
import store from './store'

import { FeatureTogglesProvider } from './context/feature-toggles/FeatureToggles.context'
import { getQueryClient } from './modules/query/queryClient'

const queryClient = getQueryClient()
class AppProvider extends React.Component {
    render(): JSX.Element {
        return (
            <QueryClientProvider client={queryClient}>
                <FeatureTogglesProvider>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </FeatureTogglesProvider>
            </QueryClientProvider>
        )
    }
}

ReactDOM.render(<AppProvider />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
