import { PossibleApis, PossiblePost } from '../../../modules/constants/Api.constants'
import AxiosInstance from '../../modules/axios/axios.instance'

export async function changeContractsToMassESig(idInstitution: number, securityToken: string): Promise<any> {
    const res = await AxiosInstance.post(
        PossibleApis.serviceContract +
            '/api/v1/'.concat(PossiblePost.CSMChangeContractToMassESig.replace(':id', idInstitution.toString())),
        {},
        {
            headers: {
                authorization: 'Bearer ' + securityToken,
            },
        },
    )
    return res.data
}
