import Axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Equality } from 'npm-medgo-toolbox'
import { Token } from '../../../Models/Token'
import { decodeToken, refreshToken } from '../authorization'
import { adminLogout } from '../logout'

let AxiosInstance = Axios.create({
    withCredentials: true,
})

const attemptRefreshToken = async (
    error: unknown,
    originalRequest: AxiosRequestConfig & { _isRetryRequest?: boolean },
    decodedToken: Token,
): Promise<AxiosPromise<unknown>> => {
    originalRequest._isRetryRequest = true
    const res = await refreshToken(decodedToken)
    if (res.status === 401) {
        await adminLogout(false)
        return Promise.reject(error)
    }
    if (res.status === 201) {
        localStorage.setItem('token', res.data.token)
    }
    return AxiosInstance(originalRequest)
}

if (typeof localStorage !== 'undefined') {
    AxiosInstance = Axios.create({
        headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
        },
        withCredentials: true,
    })

    // overriding potential default authorization header
    delete AxiosInstance.defaults.headers.common.Authorization

    AxiosInstance.interceptors.request.use(
        (config: AxiosRequestConfig): AxiosRequestConfig => {
            const token = localStorage.getItem('token')
            if (!Equality.isNullOrUndefined(token) && token !== 'undefined') {
                config.headers.authorization = `Bearer ${token}`
            }

            return config
        },
        (error): any => Promise.reject(error),
    )

    AxiosInstance.interceptors.response.use(
        (response): AxiosResponse<any> => {
            // Any status code that lie within the range of 2xx cause this function to trigger

            if (response.headers.authorization) {
                localStorage.setItem('token', response.headers.authorization)
            }
            return response
        },
        async (error): Promise<any> => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            if (error.response) {
                if (error.response.headers.authorization) {
                    localStorage.setItem('token', error.response.headers.authorization)
                }

                const originalRequest = error.config
                const originalToken = localStorage.getItem('token')

                const decodedToken = decodeToken(originalToken as string)
                if (
                    decodedToken &&
                    [401, 403].includes(error.response.status) &&
                    (error.response.data === 'Token expired' || error.response.data.message === 'Token expired') &&
                    (Equality.isNullOrUndefined(originalRequest._isRetryRequest) || !originalRequest._isRetryRequest)
                ) {
                    return attemptRefreshToken(error, originalRequest, decodedToken)
                } else if (error.response.status === 440) {
                    return adminLogout(false)
                }
            }

            return Promise.reject(error)
        },
    )
}

export default AxiosInstance
