import { IReduxAction } from '../store.types'
import InterimAgencyTypes from '../types/interimAgency.types'

const initialState: any = {
    interimAgencies: {},
    isLoadingFetchInterimAgencies: false,
}
export default function interimAgencyReducer(state: any = initialState, action: IReduxAction): void {
    switch (action.type) {
        case InterimAgencyTypes.FETCH_INTERIM_AGENCY:
            return {
                ...state,
                interimAgencies: action.payload,
            }
        case InterimAgencyTypes.SET_IS_LOADING_FETCH_INTERIM_AGENCY:
            return {
                ...state,
                isLoadingFetchInterimAgency: action.payload,
            }
        default:
            return state
    }
}
