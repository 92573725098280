import { IQueryParams } from 'npm-medgo-query'
import { isNullOrUndefined } from 'util'

import {
    IBulkSignContractsRequest,
    IContract,
    IFuseContractsRequest,
    INormalizedContracts,
} from '../../../Models/Contract'
import { IInstitution } from '../../../Models/Institution'
import { PossibleApis, PossibleFetchs, PossiblePost, PossibleUpdates } from '../../../modules/constants/Api.constants'
import { fetchFactory, fetchNormalizedFactory } from '../../../modules/factory/fetch.factory'
import { fetchByIdFactory } from '../../../modules/factory/fetchById.factory'
import {
    postByIdAndQueryParamsFactory,
    postByIdSubFieldFactory,
    postFactory,
} from '../../../modules/factory/post.factory'
import { updateFactory } from '../../../modules/factory/update.factory'
import AxiosInstance from '../../modules/axios/axios.instance'
import store from '../../store'

const ContractDefaultSelect = [
    'id',
    'missions',
    'beginAt',
    'endAt',
    'motif',
    'motifComplementaryString',
    'isDPAEGenerated',
    'DPAEGeneratedAt',
    'workedMinutes',
    'mission',
    'worker',
    'isValidated',
    'signatures',
    'institution',
    'isFused',
    'isCancelled',
    'isManual',
    'contractUrl',
    'isFullTime',
    'idInInstitution',
    'missingInfo',
    'cancelledBy',
    'validatedAt',
]
export async function fetchContractById(id: number, isWorker?: boolean): Promise<IContract> {
    return fetchByIdFactory<IContract>(
        isWorker ? PossibleFetchs.contractForWorker : PossibleFetchs.contract,
        PossibleApis.serviceContract,
    )(id, {
        select: ContractDefaultSelect,
    })
}
export async function fetchContracts(params: IQueryParams): Promise<IContract[]> {
    // @ts-ignore
    const institutions = store.getState().institutions.institutions
    return fetchFactory<IContract>(PossibleFetchs.contract, PossibleApis.serviceContract)(
        {
            select: ContractDefaultSelect,
            query: {
                institution: institutions.map((e: IInstitution): number => (!isNullOrUndefined(e.id) ? e.id : -1)),
            },
            orderby: [
                {
                    columnName: 'beginAt',
                    orientation: 'ASC',
                },
            ],
            limit: 100,
        },
        params,
    )
}

export async function fetchSignableContracts(params: IQueryParams): Promise<IContract[]> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const institutions = store.getState().institutions.institutions
    params.query = Object.assign(params.query, {
        isSigned: false,
        isValidated: true,
        isFused: false,
        isCancelled: false,
    })

    return fetchFactory<IContract>(PossibleFetchs.contract, PossibleApis.serviceContract)(
        {
            select: ContractDefaultSelect,
            query: {
                institution: institutions.map((e: IInstitution): number => (!isNullOrUndefined(e.id) ? e.id : -1)),
            },
            orderby: [
                {
                    columnName: 'beginAt',
                    orientation: 'ASC',
                },
            ],
            limit: 1000,
        },
        params,
    )
}

export async function fetchContractsCount(params: IQueryParams): Promise<number> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const institutions = store.getState().institutions.institutions
    const data = await fetchFactory<{ $count: string }>(PossibleFetchs.contract, PossibleApis.serviceContract)(
        {
            select: ['$count'],
            query: {
                institution: institutions.map((e: IInstitution): number => (!isNullOrUndefined(e.id) ? e.id : -1)),
            },
            orderby: [
                {
                    columnName: 'beginAt',
                    orientation: 'ASC',
                },
            ],
            limit: 100,
        },
        params,
    )
    return !isNaN(parseInt(data[0].$count)) ? parseInt(data[0].$count) : 0
}

export async function updateContract(id: number, newContract: IContract): Promise<IContract> {
    return updateFactory<IContract>(PossibleUpdates.contract, PossibleApis.serviceContract)(id, newContract)
}

export async function validateContract(id: number): Promise<IContract> {
    return postByIdSubFieldFactory<IContract>(PossiblePost.contractValidation, PossibleApis.serviceContract)(id)
}
export async function cancelContract(id: number): Promise<IContract> {
    return postByIdSubFieldFactory<IContract>(PossiblePost.contractCancelation, PossibleApis.serviceContract)(id)
}

export async function fetchNormalizedContracts(params: IQueryParams): Promise<INormalizedContracts> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const institutions = store.getState().institutions.institutions
    return fetchNormalizedFactory<INormalizedContracts>(PossibleFetchs.contract, PossibleApis.serviceContract)(
        {
            select: ContractDefaultSelect,
            query: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                institution: institutions.map((e: IInstitution): number => (!isNullOrUndefined(e.id) ? e.id : -1)),
            },
            orderby: [
                {
                    columnName: 'beginAt',
                    orientation: 'ASC',
                },
            ],
            limit: 100,
        },
        Object.assign({}, params, {
            query: {
                ...params.query,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                institution: institutions.map((e: IInstitution): number => (!isNullOrUndefined(e.id) ? e.id : -1)),
            },
        }),
    )
}

export async function fuseContracts(fuseContractRequest: IFuseContractsRequest): Promise<IContract> {
    return await postFactory<IContract>(PossiblePost.contractFusion, PossibleApis.serviceContract)(fuseContractRequest)
}

export async function bulkSignContracts(bulkSignContractRequest: IBulkSignContractsRequest): Promise<void> {
    await postFactory<IBulkSignContractsRequest>(PossiblePost.bulkSignContracts, PossibleApis.serviceContract)(
        bulkSignContractRequest,
    )
}

export async function regenerateContract(contractId: number): Promise<IContract> {
    return postByIdAndQueryParamsFactory<IContract>(PossiblePost.contractRegeneration, PossibleApis.serviceContract)(
        contractId,
    )
}

export async function submitContractsForValidation(): Promise<void> {
    const contractIds: number[] = store
        .getState()
        // @ts-ignore
        .contracts.contractsForValidation.filter(e => e.selected)
        .map((e: any) => e.contract.id)
    await postFactory<{ contractIds: number[] }>(PossiblePost.contractsValidation, PossibleApis.serviceContract)({
        contractIds,
    })
}
export const zipContractsAndSendEmail = async (contractIds: number[]): Promise<any> => {
    AxiosInstance.post(`${process.env.REACT_APP_CONTRACT_URL}/api/v1/aws/`, {
        contractIds,
    })
}
