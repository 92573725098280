import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import langReducer from './lang.reducer'
import missionsReducer from './missions.reducer'
import contractsReducer from './contracts.reducer'
import adminsReducer from './admins.reducer'
import motifsReducer from './motifs.reducer'
import institutionsReducer from './institutions.reducer'
import institutionGroupReducer from './institutionGroup.reducer'
import appReducer from './app.reducer'
import workersReducer from './workers.reducer'
import signatureReducer from './signature.reducer'
import missionPeriodsReducer from './missionPeriods.reducer'
import servicesReducer from './services.reducer'
import templatesReducer from './template.reducer'
import levelsReducer from './level.reducer'
import interimAgencyHasInterimAgencyRequestReducer from './interimAgencyHasInterimAgencyRequest.reducer'
import interimAgencyReducer from './interimAgency.reducer'

export default combineReducers({
    auth: authReducer,
    language: langReducer,
    contracts: contractsReducer,
    missions: missionsReducer,
    motifs: motifsReducer,
    institutions: institutionsReducer,
    institutionGroups: institutionGroupReducer,
    app: appReducer,
    workers: workersReducer,
    signatures: signatureReducer,
    admins: adminsReducer,
    missionPeriods: missionPeriodsReducer,
    services: servicesReducer,
    templates: templatesReducer,
    level: levelsReducer,
    interimAgencyHasInterimAgencyRequest: interimAgencyHasInterimAgencyRequestReducer,
    interimAgency: interimAgencyReducer,
})
