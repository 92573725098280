import AxiosInstance from '../../src/modules/axios/axios.instance'
import { PossibleApis, PossibleUpdates } from '../constants/Api.constants'

export function updateFactory<T>(
    modalName: PossibleUpdates,
    api = PossibleApis.serviceApp,
): (id: number, newObject: T) => Promise<T> {
    return async (id: number, newObject: T): Promise<T> => {
        const res = await AxiosInstance.put(api + '/api/v1/'.concat(modalName, '/', id.toString()), newObject)
        return res.data
    }
}

export function updateSubFieldFactory<T>(
    modalName: PossibleUpdates,
    api = PossibleApis.serviceApp,
): (id: number, newObject: T) => Promise<T> {
    return async (id: number, newObject: T): Promise<T> => {
        const res = await AxiosInstance.put(api + '/api/v1/'.concat(modalName.replace(':id', id.toString())), newObject)
        return res.data
    }
}
