enum MissionActionTypes {
    FETCH_MISSIONS = 'FETCH_MISSIONS',
    FETCH_MISSION_BY_ID = 'FETCH_MISSION_BY_ID',
    FETCH_NORMALIZED_SIGNABLE_MISSIONS = 'FETCH_NORMALIZED_SIGNABLE_MISSIONS',
    FETCH_NORMALIZED_MISSIONS = 'FETCH_NORMALIZED_MISSIONS',
    FETCH_NORMALIZED_SELECTED_MISSIONS = 'FETCH_NORMALIZED_SELECTED_MISSIONS',
    SET_SELECTED_INSTITUTION_ID = 'SET_SELECTED_INSTITUTION_ID',
    SET_SELECTED_JOB_ID = 'SET_SELECTED_JOB_ID',
    SET_SELECTED_IS_FULL_TIME = 'SET_SELECTED_IS_FULL_TIME',
    SET_SELECTED_SERVICE_ID = 'SET_SELECTED_SERVICE_ID',
    SET_SELECTED_TEMPLATE_ID = 'SET_SELECTED_TEMPLATE_ID',
    SET_IS_LOADING_MISSION_BY_ID = 'SET_IS_LOADING_MISSION_BY_ID',
    SET_SELECTED_NORMALIZED_MISSIONS = 'SET_SELECTED_NORMALIZED_MISSIONS',
    SET_IS_LOADING_MISSIONS = 'SET_IS_LOADING_MISSIONS',
    SET_IS_LOADING_NORMALIZED_MISSIONS = 'SET_IS_LOADING_NORMALIZED_MISSIONS',
    FETCH_NORMALIZED_CONTRACTS_VALIDATION_MISSIONS = 'FETCH_NORMALIZED_CONTRACTS_VALIDATION_MISSIONS',
}
export default MissionActionTypes
