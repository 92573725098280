import { generateParams, setGenerateSearch } from 'npm-medgo-query'

function getInstitutionServiceValue(param: any): string {
    return (
        param.institution +
        (param.service && param.service !== -1 ? 's' + param.service + (param.value ? 's' + param.value : '') : '')
    )
}

function getIdBooleanValue(param: any): string {
    return param.institution + (param.value ? 's' + param.value : '')
}

function getJobProfiles(values: any[]): string {
    return values.map((e: any): string => `${e.job}s${e.institutions.join('a')}s${e.specialtys.join('a')}`).join(',')
}

function getIsDeletedFromNetworkByInstitutions(value: any): string {
    return `isDeletedFromNetworkByInstitutions:${value.institutions
        .map((e: any): string => e + 's' + value.value)
        .join(',')}`
}

function generateSearch(query: any, key: any): string {
    switch (key) {
        case 'jobProfiles':
            return (
                'jobprofiles:' +
                query[key]
                    .map((e: any): string =>
                        e.job !== -1
                            ? e.specialtys.length > 0
                                ? e.specialtys
                                      .map((spec: any): string =>
                                          e.institutions && e.institutions.length > 0
                                              ? e.institutions.map((i: any): string =>
                                                    ('' + e.job).concat(
                                                        spec !== -1
                                                            ? 's' + spec + (i !== -1 ? 's' + i : '')
                                                            : i !== -1
                                                            ? 'ss' + i
                                                            : '',
                                                    ),
                                                )
                                              : ('' + e.job).concat(spec !== -1 ? 's' + spec : ''),
                                      )
                                      .join(',')
                                : e.institutions && e.institutions.length > 0
                                ? e.institutions.map((i: any): string => ('' + e.job).concat(i !== -1 ? 'ss' + i : ''))
                                : ''
                            : '',
                    )
                    .filter((e: any): boolean => e !== '')
                    .join(',')
            )

        case 'jobProfilesPending':
            return 'jobProfilesPending:' + getJobProfiles(query[key])
        case 'isBlockedByAdmin':
            return 'isBlockedByAdmin:' + getInstitutionServiceValue(query[key])
        case 'isRefusedByWorker':
            return 'isRefusedByWorker:' + getInstitutionServiceValue(query[key])
        case 'isDayRefused':
            return 'isDayRefused:' + getIdBooleanValue(query[key])
        case 'isNightRefused':
            return 'isNightRefused:' + getIdBooleanValue(query[key])
        case 'isWorkerNotified':
            return 'isWorkerNotified:' + getIdBooleanValue(query[key])
        case 'isAllowedDay':
            return 'isAllowedDay:' + getIdBooleanValue(query[key])
        case 'isAllowedNight':
            return 'isAllowedNight:' + getIdBooleanValue(query[key])
        case 'isAllowedService':
            return 'isAllowedService:' + getInstitutionServiceValue(query[key])
        case 'range':
            return 'range:' + query[key].startDate.unix() * 1000 + ',' + query[key].endDate.unix() * 1000
        case 'rangeBeginAt':
            return `rangeBeginAt:${query[key].startDate.unix() * 1000},${query[key].endDate.unix() * 1000}`
        case 'isDeletedFromNetworkByInstitutions':
            return getIsDeletedFromNetworkByInstitutions(query[key])
        default:
            return '' + key + ':' + query[key]
    }
}
setGenerateSearch(generateSearch)
export default generateParams
